@import 'styles/helpers';
@import 'styles/typography';

.Sidebar-Body-Wrapper {
  max-height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &--DesignQueue {
    margin-top: 1.5rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.Sidebar-Admin-Menu {
  margin-left: 2.4rem;
  padding-left: 1rem;
  border-left: 1px solid $light-gray;

  .Menu-Item .Menu-Item-Child {
    font-size: $fontSizeMediumSmall;
  }
}
