@import 'styles/helpers';
@import 'styles/typography';

.CreateNewDesignModal {
  &-Title {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    color: $dark-gray;
  }

  .ant-input {
    width: 100%;
    font-size: $fontSizeSmall;

    &::placeholder {
      color: $label-gray;
    }
  }

  &-ButtonWrapper {
    width: 100%;
    margin-top: 6rem;
    display: flex;
    justify-content: flex-end;

    button {
      &:first-of-type {
        margin-right: 0.8rem;
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        color: $white;
        background: $light-gray;
        border: 0.1rem solid $neutral-gray;
      }
    }
  }
}
