.Form {
  &-Input {
    &-Error,
    &-Error .ant-select-selector {
      border-color: $red !important;
    }
  }

  &-Error {
    max-width: 100%;
    color: $red;
    font-size: $fontSizeExtraSmall;
    padding-top: 0.5rem;

    &--Short {
      width: 12.6rem;
    }

    &--Medium {
      width: 26rem;
    }
  }
}
