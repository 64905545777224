@import 'styles/helpers';
@import 'styles/typography';

.DropdownMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $fontSizeExtraSmall;

  &-Wrapper {
    position: absolute;
    top: 2.6rem;
    right: 0;
    background: $white;
    border: 0.1rem solid $neutral-gray;
    border-radius: $borderRadiusSm;
    padding: 1.9rem;
    white-space: nowrap;
    z-index: 3;
  }

  &-Item {
    display: flex;
    align-items: center;
    color: $dark-gray;
    background: $white;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: $main-blue;

      svg path {
        fill: $main-blue;
      }
    }

    & button:disabled {
      border: inherit;
      background: inherit;
      padding: 0;
      display: flex;
      align-items: center;
      color: $mid-gray;

      svg path {
        fill: $mid-gray;
      }
    }

    svg {
      width: 1.6rem;
      height: 1.65rem;
      margin-right: 1rem;
    }
  }

  li:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  li:last-child {
    svg {
      width: 1.6rem;
    }
  }
}
