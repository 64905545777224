@import 'styles/helpers/';

.AccountCompanyInfoLogo {
  width: 10.4rem !important;
  height: 10.4rem !important;
  margin-bottom: 2.6rem !important;
  background-color: $blue-gray;

  &-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 6.07rem;
  }

  &-UploadInput {
    display: none;
  }

  &-Button {
    margin-right: 2.75rem;

    &-Wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 5rem;
    }
  }

  &-Spinner {
    position: absolute;
    margin-left: 43%;
    margin-top: 4.2rem;
    z-index: 2;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-left: 39.5%;
    }
  }
}
