@import 'styles/helpers/';
@import 'styles/typography/';

.PublicReportDisplay {
  h2,
  p,
  h3 {
    margin-bottom: unset;
  }

  &-Spinner {
    display: flex;
    justify-content: center;

    .ant-spin {
      margin-top: 20rem;
    }
  }

  &-Logo {
    height: 20rem;
    width: auto;
    margin: 5rem 0;

    @media only screen and (max-width: $breakpoint-sm) {
      height: 12rem;
      margin: 3rem 0;
    }
  }

  &-LogoWrapper {
    display: flex;
    justify-content: center;
  }

  &-HeadingWrapper,
  &-Inspector,
  &-ProjectInfo,
  &-SectionHeader {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &-HeadingWrapper {
    padding: 5rem 0;
    background-color: $gold;

    h2,
    p {
      color: $white;
    }

    h2 {
      font-weight: $fontWeightBold;
      font-size: $fontSizeExtraLarge;
      margin-bottom: 1rem;
    }

    p {
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeMedium;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 3rem 0;

      h2 {
        font-size: $fontSizeLarge;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: $fontSizeMediumSmall;
      }
    }
  }

  &-Inspector {
    margin: 2rem 0;

    @media only screen and (max-width: $breakpoint-sm) {
      h3 {
        font-size: $fontSizeMediumSmall;
      }

      p {
        font-size: $fontSizeExtraSmall;
      }
    }
  }

  &-SectionHeader {
    padding: 3rem 0;
    background-color: $dark-gray;

    h2 {
      color: $white;
      font-weight: $fontWeightBold;
      font-size: $fontSizeExtraLarge;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      h2 {
        font-size: $fontSizeLarge;
      }
    }
  }

  &-ProjectInfoWrapper {
    padding-top: 3rem;
    background-color: $dark-gray;
  }

  &-Address {
    margin: 2rem 0 4rem 0;
    padding: 2rem 4rem;
    background-color: $dark-gray;
    border-radius: 3rem;
    color: $white;
    width: 40rem;
    text-align: center;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 20rem;
      padding: 1rem 2rem;
      margin: 2rem 0;
      border-radius: 1rem;
      font-size: $fontSizeExtraSmall;
    }
  }

  &-ProjectInfo {
    border-top-right-radius: 5rem;
    background-color: $white;
    padding: 5rem 0;

    h2 {
      font-size: $fontSizeExtraLarge;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 3rem 0;

      h2 {
        font-size: $fontSizeMedium;
      }
    }
  }

  &-DataWrapper {
    padding: 5rem 7rem;

    div {
      margin-bottom: 2rem;
    }

    p {
      font-size: $fontSizeMediumSmall;
      text-align: justify;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 3rem;

      .subwrapper {
        h3 {
          font-size: $fontSizeMediumSmall;
        }

        p {
          font-size: $fontSizeExtraSmall;
        }
      }
    }
  }

  &-FillInItem {
    font-size: $fontSizeMedium !important;
    border-bottom: 1px solid $gray;
    margin-bottom: 3rem !important;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: $fontSizeExtraSmall !important;
    }
  }

  &-Footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: $fontSizeSmall;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      p {
        font-size: $fontSizeExtraExtraSmall;
      }
    }

    .Logo {
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      width: 18rem;

      @media only screen and (max-width: $breakpoint-sm) {
        width: 10rem;
      }
    }
  }
}
