@import 'styles/helpers';
@import 'styles/typography';

.DesignTile {
  position: relative;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    margin-bottom: 1.6rem;
  }

  &-Wrapper {
    display: flex;
  }

  &-Card {
    width: 25.6rem;
    min-height: 41.6rem;

    &--Selected {
      border: 0.2rem solid $main-blue !important;
      filter: drop-shadow(0 0 1rem $gray);
    }

    .titleWrapper {
      position: relative;
      width: 100%;
    }
  }

  &-Name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .ant-card {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $light-gray;
    overflow: hidden;

    & > .ant-card-cover {
      height: 16rem;

      > img {
        height: 16rem;
        object-fit: cover;
        object-position: center;
      }
    }

    &-hoverable {
      &:hover {
        border: 0.1rem solid $neutral-gray;
      }
    }

    & > .ant-card-body {
      padding: 1.7rem;
      width: 100%;
    }

    & .ant-card-meta {
      margin-top: -0.8rem;

      &-title {
        color: $dark-gray;
        font-weight: $fontWeightNormal;
        font-size: $fontSizeLarge;
      }
    }
  }

  &-Info {
    font-size: $fontSizeSmall;
    margin-bottom: 0.8rem;
    color: $label-gray;

    span {
      color: $dark-gray;
      font-weight: $fontWeightBold;
    }
  }

  &-Date {
    color: $main-blue;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightNormal;
    margin-top: 1.6rem;
    margin-bottom: 0;
  }

  &-Footer {
    margin-top: 1.6rem;
    margin-left: -0.1rem;
    margin-right: -0.1rem;
    display: flex;
    justify-content: space-between;

    .ant-radio-button-wrapper {
      font-size: $fontSizeSmall;
      height: 3.4rem;
      padding: 0.13rem 1.6rem;
      border: 0.1rem solid $neutral-gray;
      color: $dark-gray;

      &:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }

      &:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }

      &-checked {
        background: $main-blue !important;
        border-color: $main-blue;
      }
    }
  }

  &-ScaniflyLink {
    &.ant-btn {
      font-size: $fontSizeSmall;
      font-weight: $fontWeightNormal;
      color: $dark-gray;
      height: 3.4rem;
      border-color: $neutral-gray;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }

    button {
      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        font-size: $fontSizeSmall;
        font-weight: $fontWeightNormal;
        color: $white;
        background: $light-gray;
        border: 0.1rem solid $neutral-gray;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        height: 3.4rem;
        cursor: not-allowed;
      }
    }
  }

  .Menu-Mini {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  .DropdownMenu {
    button {
      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        cursor: not-allowed;
        border: none;
        padding: 0;
        background: transparent;
        color: #c3c7d1;
      }
    }
  }

  .DropdownMenu-Wrapper {
    top: 4.25rem !important;
    right: 1.5rem !important;
  }

  .DropdownMenu-Item {
    svg {
      margin-top: -0.2rem;
    }
  }
}
