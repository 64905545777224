@import 'styles/helpers';
@import 'styles/typography';

.DesignServices {
  &-Heading {
    font-size: $fontSizeMedium;
    color: $dark-gray;
    font-weight: $fontWeightSemiBold;
    margin: 3rem 0 1rem 0;
  }

  &-Title {
    font-size: $fontSizeSmall;
    color: $label-gray;
    white-space: pre-wrap;
    margin-right: 0.5rem;
  }

  &-SelectMainWrapper {
    display: flex;
    justify-content: space-between;
  }

  &-Dropdown {
    width: 100%;
  }

  &-DropdownWrapper {
    display: flex;
    flex-direction: column;
    width: 47%;
  }

  &-ButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
  }

  &-CreditsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-top: 2rem;
    font-size: $fontSizeMediumSmall;
  }

  &-TotalCreditDisplay {
    font-size: $fontSizeMedium;
    color: $dark-gray;
    font-weight: $fontWeightSemiBold;
    width: 50%;
  }

  &-Notification {
    margin-right: 1rem;
  }

  &-CreditValue {
    margin-left: 0.5rem;
    font-weight: $fontWeightNormal;
  }

  &-Note {
    color: $label-gray;
    font-size: $fontSizeExtraSmall;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    &-SelectMainWrapper {
      flex-direction: column;
    }

    &-NotificationWrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    &-ButtonWrapper {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1rem;
    }

    &-DropdownWrapper {
      width: 100%;
    }

    &-Button {
      margin-top: 2rem;
    }

    &-TotalCreditDisplay,
    &-Note {
      width: 100%;
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    &-Heading {
      margin-top: 2rem;
    }
  }
}
