@import 'styles/helpers';

.ant-dropdown-menu-item {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  background: $light-blue-fifteen-percent;
}

.ant-dropdown-menu-item:last-of-type,
.ant-dropdown-menu-submenu-title:last-of-type {
  border-radius: 0 0 $borderRadiusXs $borderRadiusXs;
}

.ant-dropdown-menu-item:first-of-type,
.ant-dropdown-menu-submenu-title:first-of-type {
  border-radius: $borderRadiusXs $borderRadiusXs 0 0;
}

.ant-dropdown > .ant-table-filter-dropdown {
  border-radius: $borderRadiusSm;
}

.ant-table-filter-dropdown > .ant-dropdown-menu {
  max-height: 35rem;
}
