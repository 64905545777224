@import 'styles/helpers';
@import 'styles/typography';

.Single-Filter {
  &-Dropdown {
    position: absolute;
    top: 3.8rem;
    border: 0.1rem solid $neutral-gray;
    background-color: $white;
    border-radius: $borderRadiusXs;
    z-index: 11;
    width: 100%;
    max-width: 50rem;
  }

  &-Header {
    padding: 1rem 1.6rem 0.4rem;
    display: flex;
    justify-content: space-between;

    &-Info {
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
        color: $dark-gray;
        font-size: $fontSizeLarge;
      }

      h5 {
        margin: 0;
        color: $label-gray;
        margin-left: 1.2rem;
        font-size: $fontSizeMediumSmall;
        font-weight: $fontWeightNormal;
      }
    }

    & path {
      fill: $light-gray;
    }

    & .Button--Unstyled {
      padding: 0;
    }
  }

  &-Footer {
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;

    & .Button--Unstyled {
      padding: 0;
    }
  }

  &-Content {
    .CategoryFilter {
      &.ant-checkbox-group {
        display: flex;
        flex-direction: column;

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          min-height: 4.8rem;
          border-bottom: 0.1rem solid $neutral-gray;
          padding: 0 1.6rem;
          width: 100%;

          & > span:nth-child(2) {
            width: 80%;
            display: flex;
            align-items: center;
            height: 2.2rem;
          }

          svg {
            height: 1.2rem;
            width: 1.5rem;

            path {
              fill: $white;
            }
          }

          .ant-tag {
            display: inline-flex;
            align-items: center;
            max-width: 100%;
            color: $white;
            height: 100%;
            border: none;
            margin: 0;

            > span {
              width: 100%;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              padding-top: 0.2rem;
              padding-left: 0.8rem;
            }
          }
        }
      }
    }

    &-Search {
      padding: 0 1.5rem;

      .ant-select {
        &-selector {
          min-height: 4rem !important;
          font-size: $fontSizeMedium;
          color: $dark-gray;
        }

        &-selection-overflow {
          align-items: center !important;
        }

        &-arrow {
          display: none;
        }
      }

      &:hover {
        cursor: text;
      }
    }

    &-Input {
      width: 100% !important;
    }
  }

  &-Clear {
    color: $main-blue;
    text-decoration: underline;
    margin: 0;
    font-size: $fontSizeSmall;
  }

  &-Counter {
    color: $label-gray;
    margin-right: 0.5rem;
  }

  .ant-btn:disabled > &-Counter {
    color: inherit;
  }
}
