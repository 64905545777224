@import 'styles/helpers/';
@import 'styles/typography/';

.ScaniflyAdmin {
  &-NestedTable {
    height: 100%;
    background: $white;
    margin: 3rem;
    box-shadow:
      2rem 2rem 2rem rgba($black, 0.06),
      -0.5rem -2rem 2rem rgba($black, 0.06);

    .ant-table {
      background: $white;
      z-index: 1;
      margin-left: 0 !important;
    }

    .ant-table-row {
      background: $white;
    }

    .Button {
      &--Icon:not(.ScaniflyAdmin-DeleteBtn) {
        width: 4rem;
        padding: 0.85rem;

        svg path {
          fill: $dark-gray;
        }

        &:hover {
          svg path {
            fill: $main-blue;
          }
        }
      }

      &--White:not(.Button--Icon) {
        svg {
          margin-right: 5px;

          path {
            stroke: $dark-gray;
          }
        }

        &:hover,
        &:focus,
        &:active {
          svg path {
            stroke: $main-blue;
          }
        }

        &:disabled {
          svg path {
            stroke: $mid-gray;
          }
        }
      }
    }

    &--ActionsContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &-Error {
    &-Visible {
      display: block;
      border: 1px solid $red;
      padding: 1rem;
      border-radius: $borderRadiusXs;
      background: rgba($red, 0.06);
      margin-top: 1rem;
      color: $red;
    }

    &-NotVisible {
      display: none;
    }
  }

  &-GenerateTokenModal {
    &-ContentWrapper {
      width: 50rem;
      max-width: 100%;
    }

    &-Description {
      font-size: $fontSizeSmall;
      margin: 1.6rem 0 2rem 0;
    }

    &-TokenText {
      letter-spacing: 0.025rem;

      &:hover {
        cursor: pointer;
      }
    }

    &-Token {
      display: flex;
      align-items: center;
      background: rgba($light-gray, 0.6);
      padding: 1.25rem 1.25rem 1.25rem 2rem;
      margin-bottom: 1.5rem;
      border-radius: $borderRadiusSm;
      font-family: monospace;
      box-shadow: 0.15rem 0.15rem 0 rgba($black, 0.2);

      &:hover {
        background: rgba($light-gray, 0.7);
      }
    }

    &-SelectWrapper {
      margin: 2.5rem 0 3rem 0;
    }

    &-ButtonWrapper {
      height: 8.2rem !important;
      float: left;
      width: 38rem;
      margin-right: 3rem;

      > label {
        font-size: $fontSizeExtraSmall;
        color: $label-gray;
      }
    }
  }
}

#TokenDeleteBtn {
  height: 3.2rem;
  padding: 0 1rem;
}
