@import 'styles/helpers';
@import 'styles/typography';

.CancelOrderModal {
  &-Title {
    font-weight: $fontWeightSemiBold !important;
  }

  &-Section,
  &-CheckboxWrapper {
    display: flex;
    flex-direction: column;
    font-size: $fontSizeSmall !important;
    color: $gray !important;
  }

  &-CheckboxWrapper {
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      font-size: $fontSizeSmall !important;
      color: $gray !important;
      margin-left: 0;
      margin-bottom: 0.5rem;
    }
  }

  &-Section {
    margin-bottom: 2rem;
  }

  &-Label {
    max-width: 38rem;
  }
}
