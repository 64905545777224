@import 'styles/helpers';
@import 'styles/typography';

.QRCode {
  &-Border {
    background: #efefef;
    border: 1px solid $neutral-gray;
    border-radius: 1rem;

    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0.5rem 1rem;

          .ant-collapse-expand-icon {
            .ant-collapse-arrow {
              margin-left: 1.5rem;
            }
          }
        }

        .ant-collapse-header-text {
          max-width: 98%;
        }
      }

      .ant-collapse-content {
        display: none;
      }
    }
  }

  &-Container {
    display: flex;
    flex-direction: column;
  }

  &-MainTitle {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightBold;
    margin-bottom: 2rem;
  }

  &-MainWrapper {
    display: flex;
    background: #efefef;
    align-items: center;
    border-radius: 1rem;

    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }

  &-DownloadAppWrapper {
    background: $black;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

  &-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &-Title {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
    margin-bottom: 0.5rem;
  }

  &-Note {
    display: flex;
    padding: 1rem 1.5rem;
    margin-left: 1.5rem;
    border-left: 1px solid $neutral-gray;
    justify-content: center;
    align-items: center;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
  }

  &-QRAndDownloadAppIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media only screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
    &-Wrapper {
      border-right: none;
    }
  }
}
