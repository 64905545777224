@import 'styles/helpers/colors';

.ant-modal {
  min-width: 48rem;
  width: fit-content !important;
  margin-top: 2rem;

  &-body {
    padding: 0;
  }

  &-content {
    padding: 4rem;
    border-radius: $borderRadiusSm;

    @media only screen and (max-width: $breakpoint-md) {
      max-width: calc(100vw - 2rem);
      margin: 0 1rem;
    }
  }

  &-confirm {
    max-width: 51rem;

    & .ant-modal-body {
      padding: 1.5rem 1rem 1rem;
    }

    & .ant-modal-content {
      padding: 3.4rem;
    }

    &-btns {
      padding-top: 2rem;
      display: flex;
      justify-content: flex-end;

      > :first-child {
        margin-right: 1rem;
        border: 0.1rem solid $neutral-gray;
      }

      button {
        letter-spacing: 0.02rem;
        height: 3.8rem;
        padding: 0 1.6rem;
      }
    }

    &-content {
      line-height: 2.5rem;
      font-size: 14px;
      font-family: $fontFamily !important;
    }

    &-title {
      color: $dark-gray !important;
      margin-bottom: 1.2rem;
      font-size: 18px !important;
      font-family: $fontFamily !important;
    }
  }

  &-mask {
    background: transparentize($dark-gray, 0.5);
  }

  &-header {
    border: none;
    padding: 0;

    .ant-modal-title {
      color: $dark-gray;
    }
  }

  &-footer {
    border: 0;
    padding: 0;

    .ant-btn {
      &:first-of-type {
        @extend .Button--White;
      }

      &:last-of-type {
        @extend .Button--Blue;

        &:disabled {
          background-color: $gray;
        }
      }
    }
  }

  &-close {
    display: none;
  }
}
