.Auth {
  .ant-input,
  .ant-input-password {
    width: 40rem;
    border-color: $neutral-gray;
    border-radius: $borderRadiusXs;
    height: 5.2rem;
    font-size: $fontSizeMedium;
    padding: 1.5rem 1.6rem;

    &:not(:disabled) {
      color: $dark-gray;
    }

    &.Error {
      border-color: $red;
    }

    > .ant-input {
      height: auto;
    }

    &:hover:not(:disabled) {
      border-color: $main-blue;
    }
  }

  .ant-input::placeholder {
    color: $label-gray;
  }

  .react-tel-input {
    font-family: $fontFamily;
    font-size: $fontSizeMedium;

    .form-control {
      width: 29.6rem;
      border-radius: $borderRadiusXs;
      height: 5.2rem;
      font-size: $fontSizeMedium;
      margin-left: 10.4rem;
      padding-left: 1.6rem;
      border: 0.1rem solid $neutral-gray;
      color: $dark-gray;

      &.Error {
        border-color: $red;
      }

      &:focus,
      &:hover {
        border-color: $main-blue;
      }
    }

    .country-list {
      border-radius: $borderRadiusSm;
      box-shadow: 0 0.8rem 4rem rgba($black, 0.08);
      border: 0.1rem solid $neutral-gray;
      width: 40rem;

      > .country {
        color: $gray;
        padding: 1.2rem 0.7rem;

        > .dial-code {
          color: $gray;
        }

        &.highlight {
          color: $dark-gray;
          background-color: $focus-blue;
          font-weight: $fontWeightSemiBold;

          > .dial-code {
            color: $dark-gray;
          }
        }
      }
    }

    .flag-dropdown {
      background-color: $white;
      border-radius: $borderRadiusXs;
      border: 0.1rem solid $neutral-gray;
      width: 8rem;

      &.Error {
        border-color: $red;
      }

      &:hover,
      &:focus {
        border-color: $main-blue;
      }

      .selected-flag {
        border-radius: 1.2rem;
        width: 7.5rem;
        padding-left: 2.5rem;

        .arrow {
          border: solid $gray;
          border-width: 0 0.23rem 0.23rem 0;
          display: inline-block;
          padding: 0.35rem;
          transform: rotate(45deg) skew(3deg, 3deg);
          top: -1.1rem;
          left: 3rem;
          transition: transform 0.3s;
          opacity: 0.45;
          border-radius: 1px;

          /* &.up {
            transform: rotate(225deg) skew(3deg, 3deg);
            top: -0.5rem;
            border: solid $dark-gray;
            border-width: 0 0.25rem 0.25rem 0;
          } */
        }
      }

      &:hover {
        .arrow {
          opacity: 0.9;
        }
      }

      &.open {
        border-radius: $borderRadiusXs;
        border-color: $main-blue;

        .arrow {
          opacity: 0.9;
        }

        .selected-flag {
          border-radius: $borderRadiusXs;
        }
      }
    }
  }

  //Removing arrows form number input

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
