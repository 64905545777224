@import 'styles/helpers';

.Layout {
  &.ant-layout {
    position: relative;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;

    .ant-spin {
      margin-top: 10rem;
    }
  }

  .Content {
    position: relative;
    min-height: 100%;
  }

  @media (max-width: $breakpoint-md) {
    .Content.ant-layout {
      background: $background;
    }

    @media only screen and (max-width: 767px) {
      .Content {
        margin-left: 8rem;
      }
    }
  }
}

.ant-layout {
  background: $background !important;
}
