@import 'styles/helpers';
@import 'styles/typography';

.Designs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  overflow-y: auto;

  @media only screen and (max-width: $breakpoint-sm) {
    height: calc(100vh - 8rem);
  }

  &-Container {
    position: relative;
    width: 59.2rem;
    padding: 0 3.2rem 3.2rem;
    height: 100%;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &-NoDesigns {
    min-height: 50vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-DesignsTypes {
    margin-top: 2rem;
    border-bottom: 1px solid #ccc;

    button {
      border: none;
      background-color: transparent;
      width: 15rem;
      color: $label-gray;
      font-size: $fontSizeSmall;
      padding-bottom: 0.6rem;
      font-weight: $fontWeightBold;
      cursor: pointer;

      svg {
        margin-right: 0.8rem;
        margin-bottom: -0.7rem;
      }

      &.active {
        border-bottom: 3px solid $main-blue;
        color: $main-blue;

        svg {
          color: #000 !important;
        }
      }
    }
  }

  &-DesignsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.6rem;
    margin-top: 2.6rem;
    padding-bottom: 2.1rem;

    @media only screen and (max-width: $breakpoint-lg) {
      justify-content: center;
    }

    & > :only-child {
      flex-grow: 1;
    }

    .ant-space-item {
      display: flex;
      justify-content: center;
    }

    &--Project {
      .ant-space-item {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &-Icon {
    width: 15rem;
    margin-top: 11.5vh;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-top: 5vh;
    }
  }

  &-InfoWrapper {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.0625rem;
  }

  &-ProjectName {
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-CountDisplay {
    font-size: 1.25rem;
  }

  &-ButtonWrapper {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 590px) {
      flex-direction: column;
    }
  }

  &-NewButton {
    border-radius: $borderRadiusXs;
    border: none;

    svg {
      margin-right: 0.8rem;
      margin-bottom: -0.25rem;
      width: 15px;
      height: 15px;
    }

    &:disabled {
      background-color: $light-gray;
      color: $white;
      border-radius: 0.6rem;
      pointer-events: none;
    }

    @media only screen and (max-width: 590px) {
      margin-bottom: 2.4rem;
    }
  }

  &-ProjectType {
    display: flex;
    align-items: center;
    height: 4rem;
    border-radius: $borderRadiusXXs;
    background: $blue-gray;
    font-size: $fontSizeExtraSmall;
    padding: 0.8rem 1.5rem;

    @media only screen and (max-width: 590px) {
      justify-content: center;
    }

    &-Title {
      font-weight: $fontWeightBold;
      margin-right: 1rem;
    }

    svg {
      margin-right: 0.8rem;
      min-width: 2.4rem;
    }
  }

  &-Spinner {
    display: flex;
    justify-content: center;
    margin-top: 25vh;
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
    margin-top: -0.8rem;
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      bottom: 3.2rem;

      svg {
        margin-top: 0.8rem;
      }

      .ant-btn {
        height: 4.2rem;
      }

      > button:nth-child(2) {
        margin-left: 1.5rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        position: relative;
        margin-top: 5rem;
        bottom: 0;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;
        width: 100%;

        button {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        > button:nth-child(2) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }
    }
  }

  &-Subtitle {
    text-align: center;
    margin-top: 1rem;
  }

  .MapWrapper {
    width: auto;
    overflow: hidden;

    &-Mapbox {
      width: auto;
      min-width: calc(100vw - 86rem);
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      overflow: auto;
    }
  }

  .ProjectTile {
    pointer-events: none;

    &-Status {
      left: 1.8rem;
    }
  }
}
