@import 'styles/helpers';
@import 'styles/typography';

.MapPin {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  transform: translate(-2rem, -4rem);

  & path {
    stroke: $blue-gray;
    stroke-width: 1;
  }

  &--SatelliteView {
    & path {
      fill: $yellow;
      stroke: $dark-gray;
      stroke-width: 1;
    }
  }

  &--Checked {
    & path {
      fill: $green;
      stroke: $gray;
    }
  }

  &--NonClickable {
    cursor: initial;
  }
}
