@import 'styles/helpers';
@import 'styles/typography';

.ViewRevisionsModal {
  h2 {
    font-weight: $fontWeightBold;
  }

  &-CollapseWrapper {
    @media only screen and (min-width: $breakpoint-md) {
      width: 50rem;
    }
  }

  &-Wrapper {
    background-color: white;

    hr {
      border: 0 none !important;
      border-top: 2px dotted $light-gray !important;
      background: none;
    }

    .ant-collapse-content-box {
      color: $gray !important;
      font-size: $fontSizeSmall;
      font-weight: $fontWeightSemiBold;
      white-space: pre-line;
      padding: 0 4rem !important;

      h3 {
        font-size: $fontSizeMediumSmall;
        margin-bottom: 0;
      }
    }

    .ant-collapse-item {
      border-bottom: unset !important;
    }

    .ant-collapse-header {
      padding-bottom: unset !important;
      font-size: $fontSizeMedium;
      font-weight: $fontWeightSemiBold;
    }
  }

  &-DataWrapper {
    margin-bottom: 0.5rem;
  }

  &-Data {
    white-space: pre-wrap;
  }

  &-RevisionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: $fontSizeMedium;
      font-weight: $fontWeightSemiBold;
      margin-bottom: 0;
    }

    .date {
      font-size: $fontSizeSmall;
      font-weight: $fontWeightSemiBold;
      color: $dark-gray;
    }
  }
}

.ant-modal-body {
  margin-bottom: 1.5rem;
}
