@import 'styles/helpers';
@import 'styles/typography';

.AccountPayments {
  &-SubTitle {
    font-size: $fontSizeMediumSmall;
    padding-top: 1.5rem;
    margin-bottom: 1.25rem;
    color: $dark-gray;
  }

  &-Title {
    font-size: $fontSizeSmall;
    color: $label-gray;
    white-space: pre-wrap;
  }

  &-CardInfo {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &-Brand {
      width: 4.8rem;
      height: 4rem;
    }

    &-Message {
      margin-left: 1.3rem;

      p {
        font-size: $fontSizeMediumSmall;
        color: $label-gray;
        margin: 0;
        line-height: 1.8rem;
      }

      :last-child {
        font-size: $fontSizeMediumSmall;
        font-weight: $fontWeightBold;
      }
    }
  }

  &-ButtonWrapper {
    text-align: right;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  &-CreditValue {
    margin-left: 0.5rem;
  }

  &-Row {
    > :not(:last-child) {
      margin-right: 0.6rem;
      margin-bottom: 2rem;

      @media only screen and (max-width: $breakpoint-md) {
        margin-right: 0;
      }
    }

    .AccountPayments-Wrapper {
      flex-basis: 0;
      flex-grow: 1;

      &--Country {
        width: 100%;
        margin-bottom: 3rem;

        & > div > div {
          min-height: 4.2rem;
        }
      }

      &--Short {
        width: 12.6rem;
      }

      &--Long {
        width: 100%;
        margin-bottom: 4rem;
      }

      .ant-picker {
        width: 100%;
      }
    }
  }

  .ant-row {
    display: flex !important;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    .ant-row {
      display: block !important;
    }
  }
}
