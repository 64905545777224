@import 'styles/helpers/colors';
@import 'styles/helpers/breakpoints';

.ImagePanzoom {
  height: 52vh;
  margin-top: 2rem;
  overflow: hidden;
  cursor: move;

  @media screen and (max-width: $breakpoint-xxl) {
    height: 48vh;
  }

  @media screen and (max-height: $breakpoint-md) {
    height: 40vh;
  }

  &-TransformComponent {
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
  }

  &-Image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: transform ease-in-out 350ms;
    pointer-events: all !important;

    &--Portrait {
      margin: auto;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin-left: 0%;
      margin-top: 0%;
    }
  }

  &-Button {
    &-Wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-Reset {
      background: none;
      height: 3.5rem !important;
      width: 3.5rem !important;
      border: 1px solid $white !important;
      border-radius: 0.1rem;
      margin: 2rem 2.5rem;

      svg {
        margin: -1.2rem -1rem -1rem !important;
        vertical-align: middle;

        path {
          fill: $white;
        }
      }

      &:hover,
      &:focus {
        background: none;
      }

      &:focus-visible {
        border: 0.2rem solid $main-blue;
      }

      &:active {
        background: $white-ten-percent;
      }
    }

    &-Toggle {
      background: none;
      outline: 0.1rem solid $white;
      margin: 2rem 2.5rem;

      &:hover,
      &:focus {
        background: none;
        outline: 0.1rem solid $white;
        color: $white;
      }
    }
  }
}
