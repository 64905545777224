@import 'styles/helpers';
@import 'styles/typography';

.IntegrationCard {
  .integrationIcon {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  .settings {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: $label-gray;
    font-size: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      margin-left: 1rem;
    }
  }

  .name {
    position: absolute;
    top: 6.6rem;
    left: 1.5rem;
    font-weight: $fontWeightBold;
    font-size: $fontSizeLarge;
    line-height: 2rem;
  }

  .destination {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 9.5rem;
    left: 1.5rem;
    font-weight: $fontWeightNormal;
    font-size: $fontSizeMediumSmall;
    width: 90%;

    span.title {
      color: $neutral-gray;
    }

    div.value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $label-gray;
      margin-left: 0.5rem;
    }
  }

  .IntegrationConnection {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;

    .connection-status {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $label-gray;
      cursor: default;

      .connected {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dbf8df;
        border: 1px solid #27e2a4;
        border-radius: 8px;
        color: #27e2a4;
        padding: 10px 20px;
        margin-right: 20px;
        font-size: 14px;

        svg {
          margin-right: 10px;
        }
      }

      .disconnected {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fceeed;
        border: 1px solid #e14942;
        border-radius: 8px;
        color: #e14942;
        padding: 10px 20px;
        margin-right: 20px;
        font-size: 14px;

        svg {
          margin-right: 10px;
        }
      }

      // eslint-disable-next-line
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}

.IntegrationDisconnectConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .close-modal-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .title {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 500;
    color: $dark-gray;
  }

  button {
    margin-right: 1rem;
  }
}
