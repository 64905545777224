@import 'styles/helpers';

.AccountLoginSecurityCredentials {
  &-Form {
    &-Error {
      @media only screen and (max-width: $breakpoint-md) {
        width: 100% !important;
      }

      &-Email {
        margin-bottom: -2.5rem;
      }
    }

    &-Row {
      margin-bottom: 0.8rem;

      &-Password {
        & > div:first-child {
          margin-right: 0.6rem;

          @media only screen and (max-width: $breakpoint-md) {
            margin-right: 0;
          }
        }
      }

      &-Email {
        margin-bottom: 3rem;

        & :first-child {
          width: 100%;
        }
      }
    }

    &-Wrapper {
      @media only screen and (max-width: $breakpoint-lg) {
        height: fit-content !important;
      }
    }
  }

  &-Button {
    margin-left: 1.6rem;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 4rem;
    }

    &-Icon {
      &--Hidden {
        display: none;
      }
    }
  }
}
