@import 'styles/helpers';
@import 'styles/typography';

.Sidebar {
  &-Logo {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;

    &--Hidden {
      display: none !important;
    }

    &--Full {
      width: 18rem;
    }

    svg {
      min-height: 4.75rem;
      max-height: 4.75rem;
      transition-delay: 0.2s;
    }
  }

  .ant-layout-sider {
    &-children {
      position: fixed;
      width: 24rem;
      overflow: hidden;
    }

    &-trigger {
      pointer-events: none;
      background: transparent;
      color: initial;
      text-align: right;
      margin-bottom: -0.2rem;
      margin-left: -3.2rem;
      padding-right: 0.8rem;

      svg {
        transition: transform 0.1s;
        pointer-events: initial;
      }
    }
  }

  &--IsMobile {
    &.ant-layout-sider {
      position: fixed;
    }
  }

  &.ant-layout-sider {
    background: $blue-gray;
    padding: 0 2rem;
    z-index: 5;
    transition: 0.3s width 1s;

    &-collapsed {
      padding: 0;

      .ant-layout-sider-children {
        width: 8rem;
      }

      .ant-layout-sider-trigger {
        text-align: center;
        margin: 0;
        margin-bottom: -0.8rem;
        padding: 0;

        svg {
          pointer-events: initial;
          transform: rotate(180deg);
        }
      }

      .ant-menu-item-group {
        display: none;
      }
    }
  }

  .ant-menu-item-group-title {
    display: none;
  }

  .Button {
    &--Dark {
      height: 4.4rem;
      width: 100%;
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeMedium;
      line-height: 4.4rem;
      border-radius: $borderRadiusXs;

      > .ant-btn {
        height: 4.4rem;
        width: 100%;
        font-weight: $fontWeightSemiBold;
        font-size: $fontSizeMedium;
        line-height: 22px;
        border-radius: $borderRadiusXs;
      }
    }

    &--Disabled {
      background-color: $light-gray;
      max-width: 100% !important;
      width: 100% !important;
      cursor: not-allowed;

      > .ant-btn {
        background-color: $light-gray;
      }

      & span {
        vertical-align: middle;
      }
    }
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 99.5%;

    &-vertical {
      border-right: 0;
    }

    &-item a,
    &-item-active {
      color: $dark-gray;
    }

    &-item {
      font-weight: $fontWeightBold;
      font-size: $fontSizeMedium;
      letter-spacing: 0.02rem;
      line-height: 2.2rem !important;
      height: auto !important;
      margin-bottom: 0.8rem !important;
      padding: 0.75rem 0 0.75rem 1rem !important;
      border-radius: 6px;

      &:hover {
        background-color: $blue-gray-lowlight !important;
        color: $dark-gray;
      }

      &.Logo {
        margin-bottom: 0.25rem !important;
        padding: 0 !important;
        margin-left: -0.2rem !important;
      }

      &.NewProject {
        margin-bottom: 2.5rem !important;
        padding: 0 !important;
      }

      a:hover {
        color: $dark-gray;
      }

      span:only-of-type {
        display: inline-block;
        max-width: 14rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ant-menu-item-selected {
      background-color: $blue-gray-lowlight !important;
    }

    .ant-menu-item-selected.Help,
    .ant-menu-item-selected.Logo,
    .ant-menu-item-selected.NewProject {
      background-color: $blue-gray !important;
    }

    .ant-menu-item-group {
      a {
        font-weight: $fontWeightSemiBold;
        font-size: $fontSizeMediumSmall;
        letter-spacing: 0.02rem;
      }

      .active {
        font-weight: $fontWeightBold !important;
      }
    }

    .ant-menu-item-only-child {
      height: auto;
      line-height: 1.8rem;
      margin: 1.4rem 0;

      &:first-of-type {
        margin-top: 0;
      }

      a.active {
        color: $main-blue;
        font-weight: $fontWeightBold !important;
      }
    }

    .anticon {
      height: 2rem;
      width: 2rem;
      margin: 0.5rem;
    }

    .Help {
      margin-top: auto;
      margin-bottom: 0.4rem;
      margin-right: 10rem;
    }

    .Help--Collapsed {
      margin-top: auto;
      margin-bottom: 0.4rem;
      margin-right: 0 !important;
    }

    .Button--Collapsed {
      width: auto;
      margin-left: -1rem !important;

      > .ant-btn {
        width: auto;
      }

      > button:disabled {
        background-color: $light-gray;

        & span {
          vertical-align: middle;
        }
      }
    }

    &-inline-collapsed {
      text-align: center !important;

      h3 {
        display: none;
      }

      .anticon {
        margin-right: 0;
        margin-left: 0;
      }

      .ant-menu-item {
        width: 60%;
        margin-left: 20%;
        padding: 10px !important;

        &-icon {
          line-height: 1.8rem !important;
        }

        &-selected {
          width: 60%;
          margin-left: 20%;
          padding: 1rem !important;
        }

        &-group {
          padding-left: 0;

          &-title {
            width: 3.4rem;
            padding: 0.5rem !important;
            margin-left: 2.3rem;
          }
        }

        &.Logo {
          width: inherit;
          margin-left: inherit;
          padding: inherit;
          padding: 0 !important;
          margin-bottom: -0.75rem !important;
        }

        &.NewProject {
          margin-bottom: 1.25rem !important;
        }
      }

      .Help {
        margin-bottom: 4rem;
      }
    }

    .Help,
    .Logo,
    .NewProject {
      &:hover {
        background-color: $blue-gray !important;
      }

      &:active {
        background-color: $blue-gray !important;
      }
    }
  }

  &-Submenu {
    &.ant-menu {
      margin: 0 0.25rem;
      width: auto;
      height: auto !important;

      .ant-menu-item-only-child {
        margin: 0 0 0.25rem 0 !important;
        height: auto;
        width: auto !important;
        font-size: $fontSizeMediumSmall;
        font-weight: $fontWeightSemiBold;

        &:hover {
          background-color: $blue-gray !important;

          & a {
            color: $main-blue;
          }
        }
      }

      .ant-menu-item-disabled a,
      .ant-menu-item-disabled a:hover {
        color: $dark-gray !important;
        cursor: not-allowed;
      }

      a {
        cursor: pointer;
      }

      .active {
        color: $main-blue !important;
        font-weight: $fontWeightBold;
      }

      .ant-menu-item-selected {
        background-color: $blue-gray !important;

        li a.active {
          color: $main-blue;
          font-weight: $fontWeightBold;
        }

        span {
          color: $dark-gray;
        }
      }
    }

    &-Heading {
      width: 95%;
      padding: 2rem 1.25rem 0 1.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: default;
      font-size: 1.55rem !important;
      font-weight: $fontWeightBold !important;

      & > span {
        border-bottom: 0.2rem solid $dark-gray;
        padding-bottom: 0.25rem;
      }

      &.ant-menu-item {
        margin-bottom: 0 !important;
        padding-bottom: 0.25rem !important;
      }
    }

    &-Item {
      &--Disabled a {
        color: $gray !important;
        cursor: not-allowed !important;

        &:hover {
          color: $gray !important;
        }

        &:active {
          pointer-events: none;
        }
      }
    }
  }

  &-ScaniflyAdminButton {
    &--Hidden {
      display: none !important;
    }
  }

  @media only screen and (max-width: 767px) {
    &.ant-layout-sider {
      position: absolute;
      height: 100%;
    }
  }

  @media only screen and (max-height: $breakpoint-xxs) {
    &.ant-layout-sider-collapsed {
      .ant-menu-item {
        line-height: 1.8rem !important;
      }
    }

    &-Logo {
      margin-bottom: 0;
    }

    &-Navigation {
      margin-top: 1rem;
    }

    &-NewProjectButton {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-height: 615px) {
    .ant-layout-sider {
      &-children {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        ul:first-child {
          flex-basis: 100%;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .ant-layout-sider-children {
        padding-bottom: 2.8rem;
      }
    }

    &-Help {
      position: static;
    }
  }

  &-Container {
    max-height: 72.5%;
  }
}
