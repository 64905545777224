@import 'styles/helpers/colors';
@import 'styles/typography';

.DroneDataScoreGraph {
  margin: 0 0 1.2rem 0;

  text {
    fill: $dark-gray;
    font-size: 2.4rem;
    font-weight: $fontWeightSemiBold;
  }
}
