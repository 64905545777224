@import 'src/styles/helpers';
@import 'src/styles/typography';

.SignUp {
  &-TokenExpired {
    text-align: center;

    h1 {
      font-size: $fontSizeMediumLarge;
      margin: 5.8rem 0 2rem 0;
    }

    &-Subtitle {
      color: $label-gray;
    }
  }

  &-Form {
    &-Wrapper {
      &-Select {
        min-width: 40rem;
        margin-bottom: 2.3rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        height: fit-content !important;
      }
    }

    &-Title {
      font-size: $fontSizeLarge;
      font-weight: $fontWeightBold;
      margin-bottom: 2.1rem !important;
      color: $dark-gray;
      margin-top: 1.2rem;

      @media only screen and (max-width: $breakpoint-lg) {
        text-align: center;
      }

      &--Second {
        margin-top: 4rem;

        @media only screen and (max-width: $breakpoint-md) {
          margin-top: 6.4rem;
        }
      }
    }

    &-Row {
      > :not(:last-child) {
        margin-right: 2.4rem;

        @media only screen and (max-width: $breakpoint-lg) {
          margin-right: 0;
        }
      }

      &.ant-row {
        flex-flow: row;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        justify-content: space-evenly;

        &.ant-row {
          flex-flow: row wrap;
        }
      }
    }

    &--Short {
      &.ant-input {
        width: 14rem !important;

        @media only screen and (max-width: $breakpoint-md) {
          width: 100% !important;
        }
      }
    }

    &--Medium {
      &.ant-input {
        width: 23.6rem !important;

        @media only screen and (max-width: $breakpoint-md) {
          width: 100% !important;
        }
      }
    }

    &-Error {
      color: $red;
    }

    &-QuestionMarkIcon {
      height: 2rem;
      margin-left: 1rem;
    }

    &-Label {
      max-width: 50%;
      display: flex !important;
    }
  }

  &-Footer {
    &-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-btn-link {
        color: $gray;
        margin-top: 2.4rem;
        font-size: $fontSizeMedium;
      }
    }

    &-TermsOfService {
      text-align: center;
      color: $gray;
      margin-top: 4.2rem;
      margin-bottom: 4.2rem;
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeMedium;

      > a {
        color: $main-blue;
      }
    }
  }

  .FormControl {
    &-Wrapper {
      display: flex;

      @media only screen and (max-width: $breakpoint-lg) {
        min-width: 20rem;
      }
    }
  }
}
