@import 'styles/helpers';
@import 'styles/typography';

.Login {
  &-ResetPassword {
    &-Wrapper {
      margin-top: 3.2rem;
      text-align: center;

      a {
        font-size: $fontSizeSmall;
        line-height: 1.8rem;
      }
    }
  }

  &-Error {
    &--WrongCredentials {
      margin-top: 3.2rem;
      max-width: 40rem;

      > .ant-alert {
        text-align: center;

        .ant-alert-message {
          color: $red;
        }
      }
    }
  }

  &-Button {
    margin-top: 0.9rem;
  }
}
