@import 'src/styles/typography';

.ant-tag {
  border-radius: $borderRadiusXXs;
  font-size: $fontSizeExtraExtraSmall;
  margin-right: 0.4rem;
  margin-bottom: 0.2rem;
  font-weight: $fontWeightNormal;
}

.css-11oub9a:hover {
  background-color: inherit !important;
  color: $white !important;
  border-radius: inherit;
}
