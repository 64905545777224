@import 'styles/helpers';

.AccountWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;

  &-Card {
    margin: 9rem 0 !important;
    max-width: 59.2rem;
  }

  &-GoBackButton {
    position: absolute;
    left: 3.2rem;
    top: 3.4rem;

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }

    &--SmallScreen {
      display: none;

      @media only screen and (max-width: $breakpoint-lg) {
        display: block;
        top: -4.6rem;
        position: absolute;
        left: 0;
      }
    }
  }

  .ant-input {
    &::placeholder {
      color: $label-gray !important;
    }
  }
}
