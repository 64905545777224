@import 'styles/helpers/';
@import 'styles/typography/';

.Notes {
  position: relative;
  max-width: 59.2rem;
  padding: 0 3.2rem 3.2rem;
  height: 100%;

  @media only screen and (max-width: $breakpoint-xl) {
    width: 100%;
    padding-bottom: 0.65rem;
  }

  &-Buttons {
    display: flex;
    justify-content: space-between;

    .ant-btn {
      height: 4.2rem;
    }

    &-Wrapper {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 3.2rem;
      width: 52.8rem;

      button:only-child {
        margin-left: auto;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        position: relative;
        width: 100%;
        bottom: -1.2rem;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;
        width: 100%;

        button:only-child {
          margin-left: inherit;
        }

        button:not(:first-child) {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        .ant-tooltip-disabled-compatible-wrapper {
          button {
            width: 100% !important;
          }
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }
    }

    .Button--Blue {
      & button:disabled {
        background-color: $light-gray;
        border-radius: $borderRadiusXXs;
        border: 1px solid $neutral-gray;
        color: $white;
      }
    }
  }

  &-Empty {
    height: calc(100vh - 43rem);
    color: $dark-gray;

    form {
      border-radius: 1rem;
    }

    .ant-space-vertical {
      padding: 4rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    svg {
      width: 15rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      &--Minimized {
        height: calc(100% - 71rem) !important;
      }
    }
  }

  &-End {
    height: 10rem;
  }

  &-Label {
    color: $label-gray;
    font-size: $fontSizeMediumSmall;
    font-weight: $fontWeightNormal;
    margin: -1rem 0 1.6rem 0;
  }

  &-ProjectName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 1rem;
  }

  &-Spinner {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
    margin-top: -0.8rem;
  }

  &-Files {
    font-size: $fontSizeSmall;
    color: $label-gray;
    margin: 0.3rem 0.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    svg {
      width: 1.7rem;
      height: 1.7rem;
      margin-bottom: -0.3rem;
      margin-right: 0.7rem;
    }
  }

  &-Wrapper {
    height: calc(100vh - 43rem);
    padding-right: 3.2rem;
    overflow-y: auto;
    margin: 0 -3.2rem 2.2rem 0;

    &--Scroll {
      padding-right: 2.2rem;
    }

    &--Minimized {
      height: calc(100vh - 50rem);
    }

    @media only screen and (max-width: $breakpoint-lg) {
      height: calc(100vh - 43rem);

      &--Minimized {
        height: calc(100vh - 51.2rem);
      }
    }

    @media only screen and (max-width: $breakpoint-md) {
      height: calc(100vh - 47.2rem);

      &--Minimized {
        height: calc(100vh - 54.2rem);
      }
    }
  }

  &-Form {
    div {
      border-radius: 1rem;
    }
  }
}
