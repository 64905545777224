@import 'styles/helpers';
@import 'styles/typography';

.CustomerInfoUsage {
  margin-top: 3.3rem;

  &-Form {
    margin-bottom: 5.1rem;

    &-SafariOverride {
      .ant-input {
        &:disabled {
          -webkit-text-fill-color: $dark-gray;
        }
      }
    }

    .FormControl-Wrapper {
      margin-right: 1rem;
      flex: 1 1 4.2rem;
      height: auto;
      margin-bottom: 0.8rem;

      &:last-child {
        margin: 0 0 0 4.6rem;
      }

      label {
        font-size: $fontSizeMediumSmall;

        @media only screen and (max-width: 1040px) {
          font-size: $fontSizeSmall;
          letter-spacing: -0.05rem;
        }

        @media only screen and (max-width: $breakpoint-lg) {
          font-size: $fontSizeMediumSmall;
          letter-spacing: initial;
        }
      }

      .ant-input {
        width: 100%;
        height: 4.2rem;

        &::placeholder {
          color: $label-gray;
        }

        &:disabled {
          background: $white;
          color: $dark-gray;
        }
      }

      @media only screen and (max-width: $breakpoint-xl) {
        &:last-child {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-lg) {
        &:last-child {
          margin-left: 4.6rem;
        }
      }

      @media only screen and (max-width: $breakpoint-md) {
        margin-right: 0;

        &:last-child {
          margin: 0;
          margin-bottom: 3.5rem;
        }
      }
    }
  }

  &-AnnualUsage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.2rem;

    &-Input {
      padding: 0 0.9rem;
      height: 3.4rem;
      background: $white;
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusXs;
      display: flex;
      min-width: 31.5rem;
      align-items: center;
      overflow-x: auto;
      white-space: nowrap;

      svg {
        min-width: 1rem;
        margin: 0 1rem 0 0.6rem;
      }

      @media only screen and (max-width: $breakpoint-md) {
        min-width: 100%;
      }
    }

    &-Message {
      font-weight: $fontWeightSemiBold;
      margin-right: 0.8rem;

      @media only screen and (max-width: $breakpoint-xs) {
        span:last-of-type {
          display: none;
        }
      }
    }
  }

  &-ButtonWrapper {
    width: 100%;
    margin-top: 5.1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        color: $label-gray;
        background: $light-gray;
        border-color: 0.1rem solid $gray;
        cursor: not-allowed;
      }
    }
  }

  &-TickIcon {
    &--Hidden {
      display: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
