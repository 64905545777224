@import 'styles/helpers';

.Inline-Menu-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .menu-item-label {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 0.35rem;
      height: 2rem;
    }
  }
}

.Inline-Menu-Disabled {
  pointer-events: none;
  color: $label-gray-forty-five-percent !important;
}

.Inline-Menu-Item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Child-Content-Wrapper {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
}
