@import 'styles/helpers';
@import 'styles/typography';

.AccountCompanyInfoFolders {
  &-ColorPicker {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    border: none;

    &-Wrapper {
      position: absolute;

      > div {
        position: absolute;
        z-index: 1;
        left: -1rem;
      }
    }

    &--Hidden {
      display: none;
    }
  }

  &-FolderList {
    margin: 3.25rem 0;
    display: flex;
    flex-wrap: wrap;

    > :not(:last-child) {
      margin: 0 0.8rem 0.8rem 0;
    }

    &-Folder {
      display: grid;
      grid-template-columns: auto auto 1fr auto auto;
      grid-template-rows: 1fr;
      align-items: center;
      gap: 0.2rem;
      font-size: $fontSizeExtraExtraSmall;
      color: $white;
      height: 2.6rem;
      padding: 0 0.8rem;
      background-color: $main-blue;
      border-radius: $borderRadiusXXs;
      position: relative;

      svg {
        margin-right: 0.5rem;
        height: 1.2rem;
        width: 1.5rem;

        path {
          fill: $white;
          height: 1.2rem;
          width: 1.5rem;
        }
      }

      &-ColorPicker {
        &-Wrapper {
          position: relative;

          > div {
            z-index: 1;
            left: -1.3rem;
            position: absolute !important;
          }
        }
      }

      &-TagText {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0.2rem;
      }

      &-Icon {
        cursor: pointer;
        width: 1rem;
        height: 1rem;

        &:nth-of-type(2) {
          margin-left: 0.75rem;
        }

        &:last-of-type {
          margin-right: -0.1rem;
        }
      }
    }
  }

  &-Error {
    &.ant-alert {
      margin-bottom: 3rem;

      .ant-alert-message {
        text-align: center;
        color: $red;
      }
    }
  }
}

.github-picker {
  width: 26.8rem !important;
  padding: 1.6rem 0.8rem 0.8rem 1.6rem !important;
  border: 0.1rem solid $neutral-gray !important;

  @media only screen and (max-width: 700px) {
    width: 6.5rem !important;
  }

  span {
    > div {
      width: 3.2rem !important;
      height: 3.2rem !important;
      border-radius: $borderRadiusXXs;
      margin: 0 0.8rem 0.8rem 0;
    }
  }
}
