@import 'styles/helpers';
@import 'styles/typography';

.ScaniflyInfo {
  position: relative;
  min-width: 59.2rem;
  padding: 0 3.2rem 3.2rem;
  height: 100%;

  &-SelectWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2.9rem;
    margin-top: 1.2rem;

    & > div {
      z-index: 2;
    }
  }

  &-Select--Disabled > div {
    color: $light-gray !important;
  }

  &-Input {
    margin-bottom: 1.6rem !important;
    margin-right: 1.8rem !important;

    .ant-select-selector {
      height: 4.2rem !important;
      display: flex;
      align-items: center;
    }
  }

  &-Label {
    padding-right: 2rem;
    color: $label-gray;
    font-size: $fontSizeMediumSmall;
  }

  &-ProjectName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 1rem;
  }

  &-ProjectTypes {
    margin-top: 1.2rem;
    width: 80%;

    .ant-radio-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      @media only screen and (max-width: 1320px) {
        flex-wrap: wrap;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        flex-wrap: wrap;
      }
    }
  }

  &-Button {
    &.ant-radio-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.8rem;
      flex: 1;
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusXs !important;
      line-height: 1.8rem;
      text-align: center;
      font-size: $fontSizeMediumSmall;
      padding: 1rem;
      background: transparent;
      color: $dark-gray;

      &-checked {
        background: $main-blue !important;
        color: $white;
      }

      &:hover {
        color: $main-blue;
      }

      &::before {
        padding: 0;
        background-color: transparent !important;
      }

      @media only screen and (max-width: 1370px) {
        flex-basis: 40%;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        flex-basis: 20%;
      }

      @media only screen and (max-width: $breakpoint-md) {
        flex-basis: 40%;
      }

      @media only screen and (max-width: 390px) {
        flex-basis: 100%;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        width: 12.6rem;
      }
    }
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      width: 53rem;
      bottom: 3.2rem;

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
        bottom: 0;
      }

      &-NewProject {
        justify-content: space-between;
        bottom: 0;
      }

      svg {
        margin-top: 0.8rem;
      }

      .ant-btn {
        height: 4.2rem;
      }

      > button {
        margin-bottom: 3.2rem;
      }

      > div > button:nth-child(2) {
        margin-left: 1.5rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        position: relative;
        margin-top: 5rem;
        bottom: 0;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;
        width: 100%;

        button {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        > div > button:nth-child(2) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }
    }

    &-SubmitIcon--Hidden {
      display: none;
    }
  }

  &-Spinner {
    margin-bottom: 2.9rem;
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    margin-top: -0.8rem;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
  }
}
