@import 'styles/helpers';
@import 'styles/typography';

.CustomerInfo {
  &-Map {
    flex: 1;
    min-width: 35rem;
    height: calc(100vh - 9.5rem);
    align-self: stretch;
    margin-top: 0.1rem;
    width: 100%;

    &--ManualPinSetting {
      > div {
        cursor: pointer !important;
      }
    }

    @media only screen and (min-width: $breakpoint-lg) {
      min-width: 35rem;
    }
  }

  @media only screen and (max-width: $breakpoint-lg) {
    height: initial;
    flex-direction: column;
    min-width: 100%;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    height: initial;
    flex-direction: column;

    &-Map {
      min-height: 60rem;
      flex: 1;
    }
  }
}
