@import 'styles/helpers';
@import 'styles/typography';

.FormWrapper {
  margin-bottom: 1rem;
  border-radius: 1rem;
  border: 1px solid $neutral-gray;

  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }

  .ant-card-body {
    padding: 3rem 4rem;
    min-height: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .left-column {
      padding-right: 1.5rem;
    }

    .right-column {
      padding-left: 1.5rem;
    }

    .form-row {
      margin-bottom: 2rem;
    }

    .multi-select-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .form-row {
        width: 48%;
      }

      .separator {
        display: flex;
        align-items: center;
      }
    }

    .title {
      width: '100%';
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $label-gray;
      display: flex;
      padding-left: 0.5rem;
      font-weight: $fontWeightNormal;
    }

    .input-wrapper {
      width: 100%;

      label {
        margin-bottom: 0;
      }
    }

    .form-column {
      width: 50%;

      .projectAddress {
        height: 19.5rem !important;
        color: $label-gray;
      }

      .addressWrapper {
        display: flex;
        align-items: flex-start;

        p {
          margin-bottom: 0;
          margin-top: 0.5rem;
        }
      }

      .pin {
        margin-right: 1rem;
        height: 3rem;
        width: auto;
      }

      .form-row {
        height: 9rem;
        margin-bottom: 1.5rem;

        .input-wrapper {
          width: 100%;
          min-height: 10rem;
          margin-bottom: 2rem;

          .title {
            width: '100%';
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $label-gray;
            display: flex;
            padding-left: 0.5rem;
            font-weight: $fontWeightNormal;
            white-space: nowrap;
            height: 1.5rem;

            span {
              align-self: flex-end;
            }
          }

          .input {
            display: flex;
            flex-direction: column;

            .buttonWrapper {
              display: flex;
              align-items: center;
              flex-direction: row;

              .buttonLabel {
                margin-left: 15px;
                color: $label-gray;
                font-size: 12px;
                line-height: 16px;
                width: 40%;
              }

              .upload-button {
                &.ant-btn-default {
                  margin-bottom: 0;
                  height: 4.2rem !important;

                  svg {
                    margin-right: 0.75rem;
                    margin-bottom: -0.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .action-button {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  @media only screen and (max-width: $breakpoint-xl) {
    .ant-card-body {
      .form-column {
        .projectAddress {
          height: unset !important;
          margin-bottom: 3rem;
        }
      }

      .ant-row {
        flex-direction: column !important;
      }

      .multi-select-wrapper {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        flex-wrap: nowrap;

        .form-row {
          width: 48%;
        }

        .separator {
          display: flex;
          align-items: center;
        }
      }

      .ant-col {
        width: 100%;
      }

      .left-column {
        padding-right: 0;
      }

      .right-column {
        padding-left: 0;
      }
    }
  }
}
