@import 'styles/helpers';
@import 'styles/typography';

/**
 *  This component is used in multiple locations and the styles are modified in isolated scenarios
 *  to modify a single instance of this MenuItem. Consider if you need the core component to change or
 *  if you just need a slight adjustment for a single implementation.
 */
.Menu-Item {
  display: flex;
  padding: 0.5rem 0;
  font-size: $fontSizeMedium;
  flex-direction: row;
  color: $dark-gray;
  width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &-Wrapper--disabled:hover {
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }

  &--expanded {
    justify-content: left;
    padding-left: 1rem;
  }

  &--collapsed {
    align-content: center;
    justify-content: center;
    margin: 0 1.6rem;
  }

  &[aria-current]:not([aria-current='false']) {
    font-weight: $fontWeightBold;
    color: $main-blue;
  }

  &--isSelected {
    font-weight: $fontWeightBold;
  }

  &-Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
  }

  &--disabled {
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &-Child {
    align-self: center;
    margin-left: 0.75rem;
    width: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    line-height: 2rem;

    a {
      color: $dark-gray;
    }

    &--multi {
      display: flex;
    }

    &--enabled {
      a {
        &:hover {
          color: $main-blue;
        }
      }
    }

    &--disabled {
      a {
        pointer-events: none;
      }
    }

    &--collapsed {
      display: none;
    }
  }

  &-Credits {
    color: $white;
    background-color: $black-seventy-five-percent;
    border-radius: 2rem;
    font-size: $fontSizeExtraExtraSmall;
    padding: 0 0.5rem;
    margin-left: 1.5rem;
  }
}

.Hidden {
  display: none;
}
