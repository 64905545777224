$dark-gray: #2e3134;
$blue-gray: #e4ebee;
$main-blue: #0024fc;
$dark-blue: #0024fc;
$light-blue: #008afc;
$teal: #0dcad3;
$green: #27e2a4;
$yellow: #fcca65;
$gold: #f5b211;
$orange: #f98445;
$red: #ff4d4d;
$light-purple: #b342ce;
$purple: #6e30e5;
$new-purple: #803dfd;

$blue-gray-highlight: #e4ebee97;
$yellow-highlight: #fcca65b0;

$white: #fff;
$background: #f5f5f5;
$light-gray: #dfdfdf;
$neutral-gray: #cfcfcf;
$mid-gray: #c3c7d1;
$gray: #767676;
$label-gray: #6a6f83;
$dark-gray: #2e3134;
$black: #000;

$default-folder: #8ed1fc;
$blue-gray-lowlight: #d5dde0;
$focus-blue: #0024fc33;
$light-blue-fifteen-percent: #008afc26;
$white-ten-percent: #ffffff1a;
$white-fifty-percent: #ffffff80;
$black-twenty-five-percent: #00000040;
$black-seventy-five-percent: #000000c4;
$success-background: #60d07029;
$label-gray-forty-five-percent: #6a6f8345;
$light-gray-ten-percent: #dfdfdf10;
