@import 'styles/helpers';
@import 'src/styles/typography';

.MultiCheck {
  &-FolderIcon {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.9rem;

    path {
      fill: $white;
    }
  }

  .ant-checkbox {
    min-width: 2.2rem;

    &-inner {
      display: flex;
      border-color: $gray;

      &::before {
        margin: auto;
        color: $gray;
        content: '+';
      }
    }

    &-checked {
      .ant-checkbox-inner {
        &::before {
          display: none;
        }
      }

      &::after {
        border-color: $green !important;
      }

      &:hover,
      &:focus,
      &:active {
        .ant-checkbox-inner {
          border-color: $green !important;

          &::before {
            color: $green !important;
          }
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      .ant-checkbox-inner {
        border-color: $green !important;

        &::before {
          color: $green !important;
        }
      }
    }

    &-input:focus + .ant-checkbox-inner {
      border-color: $gray !important;
    }
  }

  .ant-checkbox-group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20.6rem, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    &-item {
      align-items: center;
    }

    .ant-checkbox-wrapper {
      padding: 1.6rem !important;
      border: 0.1rem solid $light-gray !important;
      border-radius: $borderRadiusSm !important;
      color: $gray;
      margin: 0 !important;
      align-items: center;

      & > span:nth-child(2) {
        width: 95%;
        display: flex;
        align-items: center;
        height: 2.2rem;
      }

      .ant-tag {
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        color: $white;
        height: 100%;
        border: none;
        margin: 0;

        > span {
          height: 100%;
          width: 100%;
          padding-top: 0.2rem;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-checked {
        border-color: $green !important;
        color: $dark-gray;
        font-weight: $fontWeightSemiBold !important;
      }

      &:hover {
        border-color: $green !important;

        .ant-checkbox-inner {
          border-color: $green !important;

          &::before {
            color: $green !important;
          }
        }
      }

      .ant-checkbox-checked {
        border-color: $green !important;

        .ant-checkbox-inner {
          background-color: $green !important;
          border: 0.1rem solid $green !important;
        }
      }
    }
  }
}
