@import 'styles/helpers';
@import 'src/styles/typography';

.MapPopup {
  width: 22rem;
  cursor: pointer;

  &-Status {
    position: absolute;
    float: right;
    padding: 1rem;
    font-size: $fontSizeSmall;
  }

  &-Image {
    height: 15rem;
    width: 22rem;
    object-fit: cover;
    border-radius: $borderRadiusXs $borderRadiusXs 0 0;
  }

  &-Wrapper {
    padding: 1.25rem;
    width: 22rem;
  }

  &-Title {
    font-size: $fontSizeLarge;
    padding-bottom: 0.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &-Date {
    color: $label-gray;
    font-size: $fontSizeSmall;
  }
}
