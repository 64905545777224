@import 'styles/helpers';
@import 'styles/typography';

.ConfirmDeleteModal {
  position: relative;

  .ant-modal-content .ant-modal-body {
    .close-modal-icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }
  }

  &-Title {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    color: $dark-gray;
  }

  &-Buttons {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 4rem;

      > :first-child {
        margin-right: 0.8rem;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        justify-content: center;
      }
    }
  }
}
