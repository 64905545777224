@import 'styles/helpers';
@import 'styles/typography';

.ant-notification {
  &-notice {
    border-radius: $borderRadiusXs;

    &-message {
      font-weight: $fontWeightSemiBold;
    }

    &-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
    }
  }
}
