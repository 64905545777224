@import 'styles/helpers';
@import 'styles/typography';

.ChangeProjectStatusModal {
  &-Wrapper {
    max-width: 38rem;
  }

  &-Title {
    font-weight: $fontWeightBold;
    font-size: $fontSizeMedium;
    color: $dark-gray;
    margin-bottom: 2rem;
  }

  &-Select {
    &-Wrapper {
      height: 8.2rem !important;
      width: 100%;

      > label {
        font-size: $fontSizeExtraSmall;
        color: $label-gray;
      }
    }
  }

  &-Buttons {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;

      > :first-child {
        margin-right: 1.6rem;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        justify-content: center;
      }
    }
  }
}
