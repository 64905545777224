@import 'styles/helpers';
@import 'styles/typography';

.UploadThumbnail {
  width: 10rem;
  height: 7rem;
  margin: 0;
  border-radius: $borderRadiusXXs;
  object-fit: cover;
  background-color: $blue-gray;
  position: relative;

  span {
    max-width: 13.9rem;
  }

  &-Delete {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -1.65rem;
    margin-top: -0.45rem;

    svg {
      height: 1.7rem;
      width: 1.7rem;
      background: $white;
      border-radius: 100%;
    }

    &:hover {
      svg path {
        fill: $black;
      }
    }
  }
}
