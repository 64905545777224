@import 'styles/helpers';
@import 'styles/typography';

.AccountCompanyInfoCategories {
  &-Subtitle {
    color: $label-gray;
    font-size: $fontSizeExtraSmall;
    font-weight: $fontWeightNormal;
  }

  &-CategoriesList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    margin-top: 3.6rem;

    > :nth-child(odd) {
      margin-right: 0.6rem;

      @media only screen and (max-width: 700px) {
        margin-right: 0;
      }
    }

    &-Category {
      margin-bottom: 0.6rem;
      display: flex;
      justify-content: space-between;
      font-size: $fontSizeExtraSmall;
      height: 3.6rem;
      width: 25.8rem;
      padding: 1.1rem 1.4rem;
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusXs;
      color: $dark-gray;

      > div {
        display: flex;
        align-items: center;

        span {
          margin-top: 0.2rem;
        }
      }

      &-XIcon {
        cursor: pointer;

        &:hover {
          path {
            fill: $dark-gray;
          }
        }
      }

      &-Icon {
        cursor: pointer;
        margin: 0 1.2rem;

        path {
          fill: $label-gray;
        }

        &:hover {
          path {
            fill: $dark-gray;
          }
        }
      }

      &-CategoryIcon {
        margin-right: 1.2rem;
        width: 1.6rem;
        height: 1.6rem;

        path {
          fill: $dark-gray;
        }
      }

      &-Name {
        max-width: 15rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &-Button {
    &-Wrapper {
      margin-top: 2.2rem;
    }
  }

  &-TickIcon {
    width: 1.3rem;
    margin: 0 0.5rem -0.65rem;
    display: inline-block;

    circle {
      fill: $yellow;
    }
  }
}
