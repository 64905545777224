@import 'styles/helpers';

.mobileCol {
  flex-direction: column;
}

.ThreeDView {
  background: $dark-gray;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 10rem;
  min-height: calc(100vh - 9.4rem);

  &-Buttons {
    padding: 1.3rem 1.5rem;
    display: flex;

    .ant-btn,
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: transparent;
      color: $white;
      border: 0.1rem solid $white;
      display: flex;
      align-items: center;
      height: 3.4rem;
      margin-right: 1.7rem;
      opacity: 1;

      svg {
        margin-top: -0.3rem;
        margin-right: 0.8rem;
      }

      &:disabled {
        background: transparent;
        color: $white;
        opacity: 0.5;
      }
    }

    @media only screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
      .ant-btn {
        padding: 0.5rem;
        width: 100%;
      }
    }
  }

  &-Container {
    position: fixed;

    @media only screen and (max-width: $breakpoint-lg) {
      position: relative;
    }
  }

  &-Modal {
    &-Button {
      float: right;
    }

    &-Content {
      width: 45vw;
      padding-bottom: 2rem;

      textarea {
        margin: 2rem auto 1rem;
        height: 9rem;
        padding: 0.5rem;
      }
    }
  }

  &-Thumbnail {
    width: 300px;
    height: auto;
    margin-top: 100px;
    position: fixed;
  }

  .IframeWrapper {
    position: relative;
    margin-top: 6.75rem;
    right: 0;

    @media only screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
      margin-top: 11.75rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin-top: 3rem;
    }

    p {
      color: white;
      text-align: center;
    }
  }

  & iframe {
    position: fixed;

    @media only screen and (max-width: $breakpoint-lg) {
      position: relative;
      height: 65rem;
    }
  }
}
