@import 'styles/helpers';

.UploadOverlay {
  width: 112vw;
  height: 100vh;
  position: fixed;
  background-color: $black-seventy-five-percent;
  z-index: 6;
  margin-left: -29rem;

  @media screen and (max-width: $breakpoint-lg) {
    height: 200vh;
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-left: -9rem;
  }

  &-Cancel {
    margin-top: 45vh;
    margin-left: 69.5%;
    position: absolute;
    background: transparent;
    z-index: 7;
    padding: 1.2rem !important;

    @media only screen and (min-width: 1730px) {
      margin-top: 47.55vh;
    }

    @media only screen and (min-width: 1750px) {
      margin-top: 49.5vh;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin-left: 73%;
    }

    & svg {
      width: 16px;
      height: 16px;

      path {
        fill: $white;
      }
    }

    &:hover {
      background: transparent;
    }
  }
}
