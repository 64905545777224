@import 'styles/helpers';
@import 'styles/typography';

.Sidebar-Project-Menu-Container {
  margin-left: 0.5rem;

  &--collapsed {
    margin-left: 0;
  }
}

.Sidebar-Icon {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;

  &--Site-Data {
    width: 3rem;
    height: 3rem;
  }
}

[aria-current]:not([aria-current='false']) .Sidebar-Drone-Images-Score {
  background-color: $main-blue;
}
