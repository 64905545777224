@import 'styles/helpers';

.SetPassword {
  &-Form {
    &-Wrapper {
      @media only screen and (max-width: $breakpoint-lg) {
        height: fit-content !important;
      }
    }
  }

  &-Error {
    &.ant-alert {
      max-width: 40rem;
      margin-bottom: 3.2rem;
    }

    .ant-alert-message {
      color: $red;
    }
  }
}
