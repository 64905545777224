.RetryAlert {
  &-Description {
    display: flex;
    flex-direction: column;

    &-Buttons {
      align-self: flex-end;
      margin-top: 1rem;

      :nth-child(2) {
        margin-left: 1.5rem;
      }
    }

    &-Error {
      margin-top: 1rem;
    }
  }

  &.ant-alert {
    margin: 1rem 0;
    display: flex;
  }
}
