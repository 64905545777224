@import 'styles/helpers';
@import 'styles/typography';

.CustomerInfo {
  background: $background;
  min-width: 59.2rem;
  padding: 0;
  display: flex;

  &-Modal {
    width: 40rem;
    margin: 2rem 0 3rem 0;
  }

  &-Form {
    padding: 2.1rem 3.2rem 3.2rem;
    display: flex;
    height: calc(100vh - 9.4rem);
    flex-direction: column;
    justify-content: space-between;
    width: 59.2rem;
    overflow-y: auto;

    .ant-input {
      font-size: $fontSizeMedium;

      &::placeholder {
        color: $label-gray;
      }
    }

    &-ProjectName {
      .ant-input {
        width: 100%;
        height: 4.2rem;
        font-size: $fontSizeMedium;
      }

      label {
        font-size: $fontSizeMediumSmall;
      }

      &.FormControl-Wrapper {
        height: 6.6rem;
        margin-bottom: 3.5rem;
      }
    }

    &-Message {
      color: $label-gray;
      line-height: 1.8rem;

      &--Usage {
        display: block;
        font-size: $fontSizeExtraSmall;
        line-height: 1.6rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        margin-left: 2.5rem;
        margin-top: 0;

        &--Usage {
          margin-left: 1.8rem;
        }
      }

      @media only screen and (max-width: $breakpoint-md) {
        margin-top: 1.5rem;
        margin-left: 0;

        &--Usage {
          margin-left: 0;
        }
      }
    }

    &-TabWrapper {
      display: flex;
      align-items: center;

      .ant-radio-group {
        min-width: 13.8rem;
        margin-right: 2.5rem;

        > .ant-radio-button-wrapper {
          font-weight: $fontWeightNormal;
          color: $dark-gray;
          height: 3.8rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &:first-of-type {
            border-radius: $borderRadiusSmLeft;
          }

          &:last-of-type {
            border-radius: $borderRadiusSmRight;
          }

          &-checked,
          &-checked:active {
            color: $white !important;
            background: $main-blue;
          }
        }
      }

      &--Usage {
        @media only screen and (max-width: $breakpoint-xl) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media only screen and (max-width: $breakpoint-lg) {
          flex-direction: row;
          align-items: center;
        }

        .CustomerInfo-Form-Message {
          @media only screen and (max-width: $breakpoint-xl) {
            margin-top: 1.5rem;
            margin-left: 0;

            &--Usage {
              margin-left: 0;
            }
          }

          @media only screen and (max-width: $breakpoint-lg) {
            margin-left: 2.5rem;
            margin-top: 0;

            &--Usage {
              margin-left: 0;
            }
          }

          @media only screen and (max-width: $breakpoint-md) {
            margin-top: 1.5rem;
            margin-left: 0;

            &--Usage {
              margin-left: 0;
            }
          }
        }
      }

      @media only screen and (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    @media only screen and (max-width: $breakpoint-lg) {
      height: 100%;
      width: 100%;

      &-Wrapper {
        width: 100%;
        margin: 0 auto;
      }
    }

    &-Buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 5.6rem;

      &-Wrapper {
        display: flex;
        justify-content: space-between;
        bottom: 3.2rem;
        width: 100%;
      }

      .ant-btn {
        border: 0.1rem solid $neutral-gray;
      }

      .ant-tooltip-disabled-compatible-wrapper {
        border: 0.1rem solid $neutral-gray;
        border-radius: 0.9rem;
        background: transparent;
      }

      button {
        &:disabled,
        &:disabled:hover,
        &:disabled:focus,
        &:disabled:active {
          color: $white;
          background: $light-gray !important;
          cursor: not-allowed;
        }
      }

      &-Submit {
        margin-left: 1.5rem;

        &Icon--Hidden {
          display: none;
        }

        &-Wrapper {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
      }

      @media only screen and (max-width: $breakpoint-xl) {
        flex-direction: column;

        div {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
        }

        &-Submit {
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-lg) {
        flex-direction: initial;

        div {
          flex-direction: initial;
          margin-top: 0;
        }

        &-Submit {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }

      @media only screen and (max-width: $breakpoint-md) {
        flex-direction: column;

        div {
          flex-direction: column;
          margin-top: 1.5rem;
        }

        &-Submit {
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }
    }
  }

  &-Error {
    &--Usage {
      &.ant-alert {
        margin-top: 2rem;
      }
    }

    &.ant-alert {
      .ant-alert-message {
        text-align: center;
        color: $red;
      }
    }
  }

  &-SubmittedFor {
    float: right;
    color: $label-gray;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
    margin-top: -0.5rem;
  }
}
