@import 'styles/helpers';
@import 'styles/typography';

.Account {
  &-Container {
    align-self: center;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &-Wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 9.4rem);
    align-content: center;
    width: calc((45.6rem + 1.6rem) * 2);

    @media only screen and (max-width: $breakpoint-xl) {
      flex-direction: column;
      padding: 1rem;
      width: calc((45.6rem + 1rem));
    }

    @media only screen and (max-width: $breakpoint-sm) {
      min-height: calc(100vh - 8rem);
      width: 100%;
    }
  }

  &-Button .Placeholder {
    display: none;
  }

  &-Button {
    width: 100%;
    max-width: 45.6rem;
    height: 12.6rem;
    background-color: $white;
    padding: 3.2rem 2.8rem;
    border: 0.1rem solid $neutral-gray;
    border-radius: $borderRadiusSm;
    margin: 0 1.6rem 1.6rem 0;
    font-weight: $fontWeightBold;
    font-size: $fontSizeLarge;
    color: $dark-gray;
    display: flex;
    align-items: center;
    position: relative;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100% !important;
      margin: 0 0 1.6rem 0;
    }

    @media only screen and (max-width: $breakpoint-xxs) {
      height: fit-content;
      padding: 1rem;
    }

    &-Text {
      position: absolute;
      left: 12.9rem;

      @media only screen and (max-width: $breakpoint-xxs) {
        position: relative;
        left: 0;
      }
    }

    &-Icon {
      width: 7.5rem;
      height: 7.5rem;
      margin-left: 1.25rem;

      &--PaymentsInvoices {
        height: 10rem;
        width: 10rem;
        margin-left: 0.1rem;
      }

      &--TeamIcon {
        height: 10rem;
        width: 10rem;
        margin-left: 0.1rem;
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        display: none;
      }
    }
  }
}
