.ant-select {
  width: 26rem;
  min-height: 4.2rem !important;
  font-size: $fontSizeSmall;

  @media only screen and (max-width: $breakpoint-md) {
    width: 100%;
  }

  &-arrow {
    transition: transform 0.3s;
    transform-origin: center;
    margin-right: 0.1rem;

    svg {
      transform: scale(1);

      path {
        fill: $label-gray;
      }
    }
  }

  &-open {
    // USAGE with new arrow:
    // <Select defaultValue="1" suffixIcon={<DropdownArrow />}>
    // </Select>
    > .ant-select-arrow {
      transform: rotate(180deg) translate(-0.4rem, 0.2rem);
    }
  }

  &-selector {
    border-radius: $borderRadiusXs !important;
    min-height: 4.2rem !important;
    border-color: $neutral-gray !important;
  }

  &-dropdown {
    border-radius: $borderRadiusXs;
  }

  &-item {
    border-bottom: 0.1rem solid $neutral-gray;
  }

  .ant-select-selection-search-input {
    font-family: $fontFamily !important;
    color: $dark-gray;
    width: auto;
  }
}
