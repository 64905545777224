@import 'styles/helpers';
@import 'styles/typography';

.Notification {
  flex-grow: 1;
  margin-right: 3rem;

  .ant-alert {
    padding: 0.75rem 1.5rem;
    line-height: 1.5rem;
  }

  &-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-MessageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-Title {
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeMediumSmall;
    margin-bottom: 0;
  }

  &-Text {
    font-size: $fontSizeExtraSmall;
    margin-bottom: 0;
  }

  &-Icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    width: 100%;
    margin-top: 1rem;
    margin-right: unset;
    margin-bottom: 0;

    .ant-alert {
      margin: unset !important;
    }

    &-Wrapper {
      justify-content: unset;
    }
  }
}
