@import 'styles/helpers';
@import 'styles/typography';

.RevisionDataModal {
  padding: 2rem;

  .ant-modal-content {
    max-width: 60rem;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  &-Title {
    font-weight: $fontWeightSemiBold !important;
    font-size: $fontSizeMedium;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    border-bottom: 1px solid $light-gray;
  }

  &-Content {
    padding-left: 1rem;
    font-size: $fontSizeMediumSmall;
  }

  &-Subtitle {
    padding: 0 1rem;
    padding-top: 1.5rem;
  }

  &-FormInputs {
    padding-left: 3rem;
    margin: 1rem 0;
    color: $gray;

    p {
      margin-bottom: 0.5rem;
    }
  }
}
