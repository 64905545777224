@import 'src/styles/helpers/';
@import 'src/styles/typography';

.ProjectCategoryMap {
  position: relative;
  height: calc(100vh - 9.4rem);
  width: 100%;
  background-color: $black;

  &-Popup {
    width: 19rem;
    border-radius: $borderRadiusXs;

    &-Image {
      height: 14rem;
      width: 100%;
      object-fit: cover;
      border-radius: $borderRadiusXs $borderRadiusXs 0 0;
    }

    &-Name {
      padding: 1.25rem;
      font-size: $fontSizeExtraSmall;
      max-width: 19rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-ButtonWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 20px;
    right: 20px;
  }

  & .Button--Filter {
    height: 3.4rem;
  }

  &--Hidden {
    display: none;
  }
}

.mapboxgl-popup-content {
  padding: 0;
  box-shadow: 0 0 0.4rem 0.4rem $black-twenty-five-percent;
  border-radius: $borderRadiusXs;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
  border-bottom-left-radius: $borderRadiusXs;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
  border-bottom-right-radius: $borderRadiusXs;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
  border-top-left-radius: $borderRadiusXs;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
  border-top-right-radius: $borderRadiusXs;
}
