@import 'styles/helpers';
@import 'styles/typography';

.FeatureFlags {
  &-List {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: $fontSizeMediumSmall;

    &-Item {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-toggler-wrapper {
        display: block;
        margin-right: 1rem;
        width: 3rem;
        height: 1.6rem;
        cursor: pointer;
        position: relative;

        input[type='checkbox'] {
          display: none;
        }
      }

      &-toggler-wrapper input[type='checkbox']:checked + &-toggler-slider {
        background-color: $main-blue;
      }

      &-toggler-wrapper &-toggler-slider {
        background-color: #ccc;
        position: absolute;
        border-radius: 100px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }

      &-toggler-wrapper &-toggler-knob {
        position: absolute;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        width: calc(1rem - 0.1rem);
        height: calc(1rem - 0.1rem);
        border-radius: 50%;
        left: 0.2rem;
        top: 0.3rem;
        background-color: #fff;
      }

      &-toggler-wrapper input[type='checkbox']:checked + &-toggler-slider &-toggler-knob {
        left: calc(100% - 1rem - 0.2rem);
      }
    }
  }

  &-Wrapper {
    height: 70vh;
    overflow-y: scroll;
  }
}
