@import 'styles/helpers';
@import 'styles/typography';

.InviteOrChangeTeammatesPermissions {
  &-Wrapper {
    width: 82.4rem;
    max-width: 100%;
  }

  &-Subtitle {
    font-weight: $fontWeightBold;
    font-size: $fontSizeLarge;
    color: $dark-gray;
    margin: 1.7rem 0 0.9rem;

    .ant-btn {
      padding: 0 0.8rem;
      margin-left: 1rem;
    }
  }

  &-Clear {
    color: $main-blue;
    text-decoration: underline;
    font-size: $fontSizeMediumSmall;
    height: 1rem;
  }

  &-Title {
    font-weight: $fontWeightBold;
    font-size: $fontSizeMediumLarge;
    color: $dark-gray;
    margin-bottom: 3.1rem;
  }

  &-Input {
    &.ant-input {
      height: 5.2rem;
      width: 40rem;
      font-size: $fontSizeMedium;

      @media only screen and (max-width: $breakpoint-md) {
        width: 100%;
      }

      &::placeholder {
        color: $label-gray;
      }
    }

    &-Wrapper {
      height: 8.2rem !important;

      > label {
        font-size: $fontSizeExtraSmall;
        color: $label-gray;
      }
    }
  }

  &-Select {
    &-Wrapper {
      height: 8.2rem !important;
      float: left;
      width: 38rem;
      margin-right: 3rem;

      > label {
        font-size: $fontSizeExtraSmall;
        color: $label-gray;
      }
    }
  }

  &-Button {
    &--Blue {
      width: 25.2rem;
      height: 5.4rem !important;
      border-radius: $borderRadiusSm !important;
    }

    &--Link {
      color: $main-blue !important;
      box-shadow: none !important;

      > span {
        text-decoration: underline;
      }
    }
  }

  &-Buttons {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10.25rem;

      > :first-child {
        margin-right: 4.6rem;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        justify-content: center;
      }
    }
  }
}
