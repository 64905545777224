@import 'styles/helpers';
@import 'styles/typography';

.AccountInvitation {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $breakpoint-lg) {
    margin: 2.6rem 0 0 0;
  }

  &-Title {
    font-weight: $fontWeightSemiBold;

    & span {
      margin-left: 0.65rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }

  &-Input {
    height: 6.8rem;

    > input {
      height: 3.8rem;
      width: 40rem;

      @media only screen and (max-width: $breakpoint-lg) {
        max-width: 100%;
      }
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin: -1.2rem 0 0 0;
    }

    .ant-input {
      &::placeholder {
        color: $label-gray !important;
      }
    }
  }

  &-Button-Wrapper {
    margin-left: 1rem;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    &-Button-Wrapper {
      display: flex;
      justify-content: flex-end;
      padding-top: 1rem;
    }
  }
}
