@import 'styles/helpers';
@import 'styles/typography';

.CustomerInfoForm {
  margin-top: 2.5rem;

  .ant-input {
    width: 100%;
    height: 4.2rem;
    font-size: $fontSizeMedium;
  }

  label {
    font-size: $fontSizeMediumSmall;
  }

  &-Form {
    &-Row {
      > div {
        flex: 1;

        @media only screen and (max-width: $breakpoint-xl) {
          flex-basis: 100%;
        }
      }

      > :not(:last-child) {
        margin-right: 0.8rem;

        @media only screen and (max-width: $breakpoint-xl) {
          margin-right: 0;
        }
      }
    }
  }

  .FormControl {
    &-Wrapper {
      height: 6.5rem;
      margin-bottom: 2.5rem;

      :disabled {
        background: $white;
        cursor: not-allowed;
        color: $dark-gray;
        -webkit-text-fill-color: $dark-gray;
      }

      :disabled::placeholder {
        -webkit-text-fill-color: $label-gray;
      }

      .flag-dropdown {
        background-color: $white;
        border-radius: 0.8rem;
      }

      .form-control {
        max-width: 25rem;
        margin-left: 7rem;
        border-radius: 0.8rem;
        padding-left: 1.5rem;
        height: 4.2rem;
        font-family: $fontFamily;
      }
    }
  }

  .react-geocoder {
    position: relative;

    input {
      height: 4.2rem;
      padding-right: 4.2rem;
      text-overflow: ellipsis;
    }

    span {
      display: flex;
      justify-content: flex-end;

      & svg {
        cursor: pointer;
        position: absolute;
        margin-top: 1.245rem;
        margin-right: 1.5rem;

        path {
          fill: $label-gray;
        }

        &:hover path {
          fill: $dark-gray;
        }
      }
    }

    &-results {
      background: $white;
      top: 4.2rem;
      width: 100%;
      position: absolute;
      z-index: 10;
      border-radius: 0.5rem;
      border: 1px solid $neutral-gray;
    }
  }

  &-ManualPinSetInfo {
    text-align: center;
    border-color: $main-blue;

    &-info {
      background: $focus-blue;
    }

    &.ant-alert {
      margin-bottom: 2.4rem;
    }

    .ant-alert-message {
      color: $main-blue;
    }
  }

  &-AddressItem {
    padding: 0.4rem 1.1rem;
    cursor: pointer;

    &:hover {
      background: $focus-blue;
    }
  }

  &--Required {
    padding-bottom: 0.5rem;
    font-size: $fontSizeMediumSmall;
    color: $label-gray;
  }
}
