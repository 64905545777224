@import 'src/styles/helpers';
@import 'src/styles/typography';

.PaymentField {
  &-FormControl-Wrapper {
    &--Short {
      width: 11rem;
    }
  }

  &-PaymentInput {
    width: 26rem;
    border-radius: $borderRadiusXs;
    border: 0.1rem solid $neutral-gray;
    height: 4.2rem;
    font-size: $fontSizeSmall;
    padding: 1.35rem 0.8rem;

    &--Auth {
      width: 40rem;
      border-radius: $borderRadiusXs;
      border: 0.1rem solid $neutral-gray;
      height: 5.2rem;
      font-size: $fontSizeMedium;
      padding: 1.8rem 1.6rem;
    }

    &--ExpiryDate {
      width: 14rem;

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }
    }

    &--CVCCode {
      width: 11rem;

      &--Auth {
        width: 23.6rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }
    }

    &-Error {
      &--Short {
        width: 14rem;
      }
    }

    &--Focus {
      box-shadow: 0 0 0.4rem 0.2rem $focus-blue;
    }
  }
}
