@import 'styles/helpers';
@import 'src/styles/typography';

.Projects {
  &-Filter {
    &-Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.8rem;
      padding: 0 2.1rem;

      &-Main {
        position: relative;
        display: flex;
        margin-bottom: 2.6rem;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    &-Switch {
      &-Wrapper {
        margin-left: auto;

        & > span {
          font-style: normal;
          font-weight: $fontWeightNormal;
          font-size: $fontSizeExtraSmall;
          margin-right: 0.4rem;
        }
      }
    }
  }

  &-List {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;

    &-Pagination {
      &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        & .ant-pagination {
          display: flex;
          flex-wrap: wrap-reverse;
          justify-content: center;
          flex-basis: 100%;
          margin-bottom: -0.8rem;
        }

        & .ant-pagination-total-text {
          font-size: $fontSizeMediumSmall;
          text-align: center;
          letter-spacing: 0.02rem;
          flex-basis: 100%;
          margin-top: 1.5rem;
          margin-right: 0;
        }
      }
    }

    &-TileWrapper {
      margin: 0.8rem;

      &--List {
        min-width: 100%;
        margin-top: 0;
        padding-left: 2rem;
        padding-right: 2rem;

        &:first-of-type {
          margin-top: 0.6rem;
        }

        &:only-child {
          margin-left: 0.8rem;
        }
      }
    }

    & > .Projects-List-TileWrapper:only-child {
      flex-grow: 1;
    }

    .ant-space {
      min-height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .ant-spin {
      color: $main-blue;

      i {
        background-color: $main-blue;
      }
    }

    &-NoProjects {
      height: calc(100% - 26rem);
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $dark-gray;

      @media only screen and (max-width: $breakpoint-lg) {
        display: block;
        text-align: center;
      }
    }
  }
}
