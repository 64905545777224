@import 'styles/helpers';
@import 'styles/typography';

.DesignStatus {
  &-MainWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-Wrapper,
  &-CompletedWrapper,
  &-CancelWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    border: 1px solid $light-gray;
    border-radius: 1rem;
    background-color: $white;
  }

  &-Wrapper {
    min-width: 90rem;
    max-width: 60%;
    padding: 3rem 2rem;
  }

  &-CompletedWrapper {
    min-width: 85rem;
    max-width: 90%;
    padding: 3rem 4rem;
  }

  &-CancelWrapper {
    min-width: 54rem;
    max-width: 60%;
    padding: 3rem 4rem;
  }

  &-TitleAndTagWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &-Title {
    max-width: 70%;
    font-size: $fontSizeLarge;
    margin: 2rem 0 0.5rem 0;
    text-align: center;
  }

  &-Text {
    max-width: 70%;
    text-align: center;
    margin: 1rem 0;
    font-size: $fontSizeSmall;
  }

  &-ErrorIcon,
  &-CompletedIcon {
    height: 10rem;
    width: auto;
  }

  &-CompletedIcon {
    height: 8rem;
    width: auto;
  }

  &-DroneIcon {
    height: 5rem;
    width: auto;
  }

  &-InfoWrapper {
    min-width: 100%;
    margin-top: 2rem;
  }

  &-InfoSection {
    margin: 1rem 0;
  }

  &-InfoTitles {
    display: flex;
    justify-content: space-between;
    font-size: $fontSizeExtraExtraSmall;
    font-weight: $fontWeightSemiBold;
    margin-right: 4rem;

    p {
      margin-bottom: unset;
    }
  }

  &-Divider {
    border: 0 none !important;
    border-top: 2px dotted $light-gray !important;
    background: none;
    margin: 1rem;
  }

  &-ValueWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $light-gray;
    border-radius: 1rem;
    background-color: $white;
    padding: 1rem 0;
    padding-right: 4rem;

    p {
      margin: 0 !important;
      color: $gray;
    }
  }

  &-CollapsibleValueWrapper {
    border: 1px solid $light-gray;
    border-radius: 1rem;
    background-color: $white;
    padding-top: 1rem;

    p {
      margin: 0 !important;
      color: $gray;
    }

    .ant-collapse-item {
      min-width: 100%;
      border-bottom: none;

      .ant-collapse-header {
        padding: unset;
        padding-bottom: 1rem;
        padding-right: 4rem;

        div {
          display: flex;
          align-items: center;
        }

        .ant-collapse-arrow {
          padding-top: unset;
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          border-top: 1px solid $light-gray;
          padding: 0;
        }
      }
    }
  }

  &-RevisionValueWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    padding-right: 4rem;

    p {
      margin: 0 !important;
      color: $gray;
    }

    .ant-collapse-item {
      min-width: 100%;

      .ant-collapse-header {
        padding: unset;
        padding-right: 4rem;

        div {
          display: flex;
        }
      }
    }
  }

  &-Field {
    width: 12.5%;
    display: flex;
    justify-content: center;
    font-size: $fontSizeExtraExtraSmall;
    text-align: center;

    .ant-btn {
      font-size: $fontSizeExtraExtraSmall;
      border: unset;
      padding: unset;
      height: unset;

      span {
        text-decoration: underline;
        color: $label-gray;
        font-weight: $fontWeightNormal;
      }
    }
  }

  &-ProductField {
    width: 12.5%;
    font-size: $fontSizeExtraSmall;
    display: flex;
    justify-content: center;
    text-align: center;
    color: $gray;
  }

  &-Value {
    font-weight: $fontWeightNormal;
  }

  &-Timeframe {
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeMediumSmall;
    margin-bottom: 1rem;
  }

  &-Scanifly3DButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &-ButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    min-width: 26rem;

    .Button--Blue,
    .Button--White {
      max-height: 3.25rem;
    }

    .my-projects {
      &:only-child {
        margin: 0 auto;
      }
    }
  }

  &-Button {
    margin: 0 1rem;
  }

  &-Total {
    display: flex;
    justify-content: flex-end;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 1rem;
  }

  &-Date {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    margin-bottom: 1rem;

    h1 {
      font-size: $fontSizeSmall;
      margin-bottom: 0;
    }

    p {
      margin: 0 !important;
      font-size: $fontSizeMediumSmall;
      color: $gray;
    }
  }

  &-SubWrapper {
    display: flex;
    align-items: center;
    margin: 0.75rem 0;
  }

  &-Icon {
    height: 1.5rem;
    margin-right: 1rem;
  }

  &-Link {
    font-weight: $fontWeightNormal;
    text-decoration: underline;
    color: $black;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    &-Wrapper {
      min-width: 100%;
      padding: 3rem 1rem;
    }

    &-CompletedWrapper {
      min-width: 90%;
      padding: 2rem;
    }

    &-CancelWrapper {
      min-width: 90%;
      padding: 2.5rem;
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    &-CompletedWrapper {
      min-width: 100%;
      padding: 1rem;
    }
  }
}
