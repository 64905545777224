@import 'styles/helpers/';
@import 'styles/typography/';

.ScaniflyAdmin {
  &-Wrapper {
    padding: 0 3.2rem;
  }

  &-Spinner {
    margin-bottom: 2.9rem;
  }

  &-Search {
    flex-grow: 1;
    margin: 0 0.5rem 0 2rem;
    height: 3.8rem !important;

    &-prefix {
      width: 1.7rem;
      margin-right: 1.2rem;
    }

    .ant-input {
      font-size: $fontSizeMedium;
      margin-left: 0.5rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 50% !important;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 30% !important;
    }
  }

  &-DatePicker {
    margin-left: 1.5rem;
    min-width: 16rem;

    .ant-picker {
      max-height: 3.75rem;
    }
  }

  &-SubHeader {
    display: flex;
    justify-content: space-between;
    margin: 3.4rem 0;

    p {
      margin: 0.65rem 3.5rem 0 2rem;
      color: $label-gray;
      font-size: $fontSizeMedium;
    }

    & .Button {
      &--Blue {
        svg {
          margin-right: 8px;

          path {
            fill: $white;
          }
        }

        margin: 0 1rem;
      }

      &--White {
        margin: 0 1rem;
      }
    }

    @media only screen and (max-width: $breakpoint-xl) {
      flex-wrap: wrap;
      justify-content: flex-end;
      row-gap: 2rem;
    }

    @media only screen and (max-width: $breakpoint-md) {
      p {
        display: none;
      }
    }
  }

  &-Table {
    overflow: auto;
    background: $white;
    margin: 0.5rem 0;
    border-radius: $borderRadiusXs;
    margin-bottom: 7rem;

    .Tag {
      min-height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .ant-pagination {
      margin-right: 2.55rem !important;
      margin-left: 2.55rem !important;
      position: fixed;
      bottom: 0.5rem;
      z-index: 0;

      &-options {
        display: none;
      }
    }

    th.ant-table-row-expand-icon-cell,
    td.ant-table-row-expand-icon-cell {
      width: 6rem;
    }

    th.ant-table-cell-scrollbar,
    td.ant-table-cell-scrollbar {
      display: none;
    }

    tr.ant-table-expanded-row-level-1 {
      padding: 2rem !important;
    }

    .ant-table-expanded-row-level-1 > .ant-table-cell {
      padding: 0;
    }

    .ant-table,
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background: $white;
      z-index: 1;
    }

    td.ant-table-cell-fix-right,
    td.ant-table-cell-fix-left {
      font-weight: $fontWeightSemiBold;
    }

    .ant-table-row {
      background: $white;
    }

    .ant-table-column-sorters {
      display: flex;
      justify-content: space-between;
    }

    .Button {
      &--Icon:not(.EditDeleteButton) {
        width: 4rem;
        padding: 0.85rem;

        svg path {
          fill: $dark-gray;
        }

        &:hover,
        &:focus,
        &:active {
          svg path {
            fill: $main-blue;
          }
        }
      }

      &--White {
        svg {
          margin-right: 5px;

          path {
            fill: $dark-gray;
          }
        }

        &:hover,
        &:focus,
        &:active {
          svg path {
            fill: $main-blue;
          }
        }

        &:disabled {
          svg path {
            fill: $mid-gray;
          }
        }
      }
    }

    label {
      margin-bottom: -0.15rem;
    }
  }

  &-TokenWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-ButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-CopyIcon {
      margin-left: 8px;
      font-size: 1.5rem;
      cursor: pointer;

      svg path {
        fill: $dark-gray;
      }

      &:hover {
        svg path {
          fill: $main-blue;
        }
      }
    }
  }

  &-GenerateTokenModal {
    &-Description {
      margin: 1.6rem 0 3rem 0;
    }

    &-Buttons {
      &-Wrapper {
        display: flex;
        justify-content: flex-end;

        > :first-child {
          margin-right: 1.6rem;
        }

        @media only screen and (max-width: $breakpoint-xs) {
          justify-content: center;
        }
      }
    }
  }

  &-DataDisplay {
    font-size: $fontSizeSmall !important;
    color: $black !important;
    margin: 0 3.5rem 0 2rem !important;
    display: flex;
    align-items: center;
  }

  &-CategoryTag {
    font-size: $fontSizeSmall;
    border-radius: 1rem;
    padding: 0.15rem 0.75rem;
  }
}
