@import 'styles/helpers';
@import 'styles/typography';

.Project-Overview {
  position: relative;
  width: 59.2rem;
  padding: 0 3.2rem 3.2rem;
  height: 100%;

  @media only screen and (max-width: $breakpoint-xl) {
    width: 100%;
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      bottom: 3.2rem;

      svg {
        margin-top: 0.8rem;
      }

      .ant-btn {
        height: 4.2rem;
      }

      > button:nth-child(2) {
        margin-left: 1.5rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        position: relative;
        margin-top: 5rem;
        bottom: 0;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;
        width: 100%;

        button {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        > button:nth-child(2) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }
    }
  }

  &-ProjectName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-block-end: 0.4rem;
  }

  &-Instruction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 16rem;
      margin-top: 10vh;
      margin-bottom: 0.5rem;
    }
  }

  &-Label {
    color: $label-gray;
    font-size: $fontSizeMediumSmall;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $breakpoint-md) {
      display: block;
    }
  }

  &-Button {
    &--NewCategory {
      display: flex !important;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: $breakpoint-md) {
        width: 100%;
        margin: 2.5rem 0;
      }

      &-Icon {
        width: 1.73rem;
        height: 1.73rem;
        margin-right: 1.44rem;
      }

      .ant-tooltip-disabled-compatible-wrapper {
        background: none;
        border: none;
      }

      & button:disabled {
        display: flex;
        align-items: center;
        background-color: $light-gray;
        color: $white;
        border-radius: 0.6rem;
      }
    }
  }

  &-CategoriesList {
    &-Category {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      font-size: 1.5rem;
      font-weight: $fontWeightSemiBold;
      height: 3.6rem;
      width: 36rem;
      padding: 1.1rem 1.4rem;
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusXs;
      color: $dark-gray;
      cursor: pointer;

      @media only screen and (max-width: $breakpoint-md) {
        width: 100%;
      }

      > div {
        display: flex;
        align-items: center;
      }

      &-CategoryIcon {
        width: 1.73rem;
        height: 1.73rem;
        margin-right: 1.24rem;

        path {
          fill: $dark-gray;
        }
      }

      &-RefreshIcon {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.2rem;
      }

      &-TickIcon {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.2rem;
      }

      &-XIcon {
        cursor: pointer;
        margin-right: 0.2rem;

        &:hover {
          path {
            fill: $dark-gray;
          }
        }
      }

      &-Icon {
        cursor: pointer;
        margin: 0 1.2rem;

        path {
          fill: $label-gray;
        }

        &:hover {
          path {
            fill: $dark-gray;
          }
        }
      }

      &-Name {
        max-width: 25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media only screen and (max-width: $breakpoint-sm) {
          max-width: 23.5rem;
        }
      }

      &--Disabled {
        opacity: 0.6;
        background-color: $white-ten-percent;
        cursor: not-allowed;
      }
    }
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    margin-top: -0.8rem;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
  }
}
