@import 'styles/helpers';

.FolderModal {
  &-Description {
    max-width: 38rem;
    line-height: 2.5rem;
    height: 6.2rem;
    margin: 1.6rem 0 3rem 0;
  }

  &-Buttons {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;

      > :first-child {
        margin-right: 1.6rem;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        justify-content: center;
      }
    }
  }

  &-Input {
    &.ant-input {
      width: 40rem;

      @media only screen and (max-width: $breakpoint-md) {
        width: 38rem;
      }
    }
  }
}
