@import 'styles/helpers';
@import 'styles/typography';

.CustomerInfoUsageChart {
  font-family: $fontFamily;
  text-align: left;

  .recharts-cartesian-axis-tick {
    font-size: $fontSizeMediumSmall;
  }

  &-Legend {
    display: flex;
    align-items: center;
    margin-bottom: 2.7rem;

    &-Bar {
      width: 3rem;
      height: 1.2rem;
      background: $main-blue;
      border-radius: $borderRadiusSm;
      margin-right: 0.8rem;
    }
  }
}
