@import 'styles/helpers/';
@import 'styles/typography/';

.MaintenanceReport {
  &-MainWrapper {
    display: flex;
    height: 100%;

    @media only screen and (max-width: $breakpoint-xl) {
      flex-direction: column;
      flex: 1;
    }
  }

  &-ListWrapper {
    display: flex;
    flex-direction: column;
    min-width: 55%;
    padding: 3rem;

    @media only screen and (max-width: $breakpoint-xl) {
      height: auto;
    }

    .QRCode-Border {
      .ant-collapse-borderless,
      .ant-collapse {
        background-color: unset;

        .ant-collapse-item {
          border: none;
        }
      }
    }

    &-QRCodeContainer {
      margin: 0 2rem 1rem 2rem;
    }
  }

  &-FormWrapper {
    display: flex;
    min-width: 45%;
    min-height: 100%;
    background-color: $white;
  }

  &-Board {
    min-width: 45%;
    background-color: $white;
    min-height: 100%;
  }

  &-Title {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 1rem;
  }

  &-DescriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    .description {
      color: $label-gray;
      font-size: $fontSizeExtraSmall;
      font-weight: $fontWeightSemiBold;
      margin-bottom: unset;
    }

    button {
      svg {
        margin-right: 1rem;
      }
    }
  }

  &-TableTitles {
    display: flex;
    justify-content: space-between;
    font-size: $fontSizeExtraExtraSmall;
    font-weight: $fontWeightSemiBold;
    padding: 0 1.5rem;

    p {
      margin-bottom: unset;
    }
  }

  &-Divider {
    border: 0 none !important;
    border-top: 2px dotted $light-gray !important;
    background: none;
    margin: 1rem;
  }

  &-SmallField {
    width: 20%;
    max-width: 10rem;
    font-size: $fontSizeExtraExtraSmall;
  }

  &-LargeField {
    width: 30%;
    max-width: 15rem;
    font-size: $fontSizeExtraExtraSmall;
  }

  &-Card {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    padding: 1rem;
    background-color: $white;
    border-radius: 1rem;
    border: 1px solid $light-gray;

    &:hover {
      cursor: pointer;
      border-radius: 1rem;
      border: 1px solid $neutral-gray;
    }

    p {
      margin-bottom: unset;
    }

    .small {
      width: 20%;
      max-width: 10rem;
      font-size: $fontSizeExtraSmall;
      text-align: start;
    }

    .large {
      width: 30%;
      max-width: 15rem;
      font-size: $fontSizeExtraSmall;
      text-align: start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-Placeholder {
    margin-top: 3rem;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: $fontSizeMediumSmall;
    color: $gray;
    padding: 1rem;
    background: $white;
    border: 1px solid $neutral-gray;
    border-radius: 1rem;
  }
}

.selected {
  font-weight: $fontWeightSemiBold !important;
  border: 1px solid $main-blue !important;
}
