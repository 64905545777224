@import 'styles/helpers';
@import 'styles/typography';

.EditNoteModal {
  &.ant-modal {
    min-width: 70rem !important;

    @media screen and (max-width: $breakpoint-md) {
      min-width: 80% !important;
    }
  }

  &-Title {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    color: $dark-gray;
  }

  &-Name {
    margin-bottom: 2rem;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightSemiBold;
    color: $dark-gray;
  }

  .ant-input {
    width: 100%;
    font-size: $fontSizeSmall;

    &::placeholder {
      color: $label-gray;
    }
  }

  &-ButtonWrapper {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;

    button {
      &:first-of-type {
        margin-right: 0.8rem;
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        color: $white;
        background: $light-gray;
        border: 0.1rem solid $neutral-gray;
        margin-right: 0;
      }
    }
  }

  .ant-input-textarea-show-count::after {
    right: 4.8rem;

    @media screen and (max-width: $breakpoint-md) {
      right: 1rem;
    }
  }
}
