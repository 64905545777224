@import 'styles/helpers';

.Menu-Mini {
  width: 2.2rem;
  height: 2.2rem;
  border: 0.1rem solid $neutral-gray;
  background-color: $white;
  border-radius: $borderRadiusXXs;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
}
