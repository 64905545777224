@import 'styles/helpers';
@import 'styles/typography';

.ImagePreviewMeta {
  padding: 0 2.4rem;

  &-Divider {
    border-top: 0.1rem dotted $label-gray;
    margin: 2rem 0 2.5rem;
  }

  &-Title {
    font-weight: $fontWeightBold;
    font-size: $fontSizeMedium;
    margin-bottom: 2rem;
  }

  &-List {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 4rem;
    }
  }

  &-Property {
    white-space: nowrap;
    width: calc(30% + 4rem);

    @media only screen and (max-width: 1650px) {
      width: calc(90% + 4rem);
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: calc(30% + 4rem);
    }

    &-Name {
      margin-right: 1rem;
    }
  }
}
