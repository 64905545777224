@import 'styles/helpers/';
@import 'styles/typography/';

.RemoteOnsiteModal {
  position: relative;
  min-width: 26.25rem !important;

  .ant-modal-content {
    padding: 2.5rem !important;

    .ant-modal-header {
      margin-bottom: 2rem;
    }

    .close-modal-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .formControlWrapper {
      margin-top: 1.5rem;

      .inputWrapper {
        min-height: 90px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: center;

      .remote-onsite-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 2.5rem 0;

        .button {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          border: 1px solid $neutral-gray;
          border-radius: 7px;
          width: 16.5rem;
          height: 12rem;
          font-size: $fontSizeExtraExtraSmall;
          padding: 1rem 2rem;
          background-color: white;
          cursor: pointer;

          &.remote {
            margin-right: 2.5rem;
          }

          &.maintenance {
            margin-left: 2.5rem;
          }
        }

        .not-available {
          opacity: 0.5;
          border: 1px solid #cfcfcf !important;
          background-color: ghostwhite;

          svg {
            filter: grayscale(1);
            width: unset !important;
            height: unset !important;
          }
        }

        .active {
          border: 1px solid $main-blue;
        }
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1.5rem;
        margin-right: 0;
      }
    }
  }
}
