@import 'styles/helpers';
@import 'styles/typography';

.SelectDesignButton {
  &-MainWrapper {
    margin-bottom: 1rem;
    border-radius: 1rem;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px $neutral-gray;

    .ant-card-body {
      display: flex;
      justify-content: space-between;
      white-space: pre-wrap;
      padding: 2rem 2rem 2rem 4rem;
      height: 13rem;
    }

    p {
      margin: 0;
    }
  }

  &--active {
    border: 2px solid $main-blue;
    filter: drop-shadow(0 0 0.5rem $gray);
  }

  &--disabled {
    cursor: not-allowed;
  }

  @keyframes increaseOpacity {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @media only screen and (max-width: $breakpoint-xl) {
    &-MainWrapper {
      .ant-card-body {
        padding: 2rem 2rem 2rem 4rem;
      }
    }
  }
}
