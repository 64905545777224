@import 'styles/helpers';
@import 'styles/typography';

.InfoView {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 10rem;
  color: $white;

  &-Wrapper {
    position: fixed;
    background: $dark-gray;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 10vh;

    @media only screen and (max-width: $breakpoint-lg) {
      position: relative;
      overflow-x: hidden;
      width: 100%;
      padding-bottom: 0;
    }
  }

  &-Buttons {
    padding: 1.3rem 1.5rem;
    display: flex;

    .ant-btn,
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: transparent;
      color: $white;
      border: 0.1rem solid $white;
      display: flex;
      align-items: center;
      height: 3.4rem;
      opacity: 1;

      &:disabled {
        background: transparent;
        color: $white;
        opacity: 0.5;
      }

      svg {
        margin-top: -0.3rem;
        margin-right: 0.8rem;
      }
    }
  }

  &-Content {
    padding: 2.4rem;

    &-Name {
      font-size: $fontSizeMediumLarge;
      font-weight: $fontWeightBold;
    }

    &-Thumbnail {
      width: 100%;
      margin-bottom: 3.4rem;
      margin-right: 2.1rem;

      &-Image {
        width: 100%;
        height: 32vh;
        object-fit: cover;
        border-radius: $borderRadiusXXs;
      }
    }

    &-Graph {
      &-Wrapper {
        background-color: $dark-gray;
        border-radius: $borderRadiusSm;
        height: 37rem;
        padding: 3rem;
        margin-bottom: 4.3rem;
      }
    }

    &-Shading,
    &-ProductionConsumption {
      display: flex;
      margin-bottom: 3.2rem;

      span {
        display: inline-block;
        font-weight: $fontWeightBold;
        font-size: $fontSizeMedium;
      }
    }
  }
}
