@import 'styles/helpers';
@import 'styles/typography';

.Accounting {
  &-Button {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        margin-right: 1.2rem;
      }
    }

    span {
      margin-left: 0 !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-TableFilters {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem;
    margin-bottom: 2.2rem;
    margin-top: -0.8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
    }

    &-SwitchContainer {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      @media only screen and (max-width: $breakpoint-lg) {
        flex-direction: column;
      }
    }

    &-Switch {
      padding: 0.5rem;

      span {
        vertical-align: middle;
        padding-right: 1.2rem;
        font-size: $fontSizeMediumSmall;
      }
    }
  }

  &-TooltipWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-QuestionMarkIcon {
    margin-right: 0.75rem;
  }

  &-InvoiceTitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &-RefreshIcon {
    height: 1.25rem;

    &--Spinning {
      color: $main-blue;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:hover {
      color: $main-blue;
      cursor: pointer;
    }
  }

  &-BetweenIcon {
    margin: 0 0.75rem;
  }

  &-Icon {
    margin-right: 0.75rem;
  }

  &-Spinner {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
  }

  &-Form {
    margin-top: 2.4rem;

    &-Asterisk {
      color: $red;
    }

    &-Row {
      display: grid !important;
      grid-template-columns: 2fr 1fr;

      .AccountPayments-CardInfo-Brand {
        margin: -0.75rem 0 0.75rem 0;
      }
    }

    &-CustomSelectRow {
      width: 100% !important;
      margin-bottom: 2rem;
      display: inline-block;
    }

    &-Notes {
      height: unset;

      .Accounting-Form-Row {
        grid-template-columns: 1fr;
      }

      textarea {
        width: 100% !important;
        min-height: 4.6rem;
        margin: -0.5rem 0 2rem 0;
        padding: 1.2rem;
      }
    }

    &-ProjectCount {
      &-Current {
        display: flex;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-left: 1rem;
          margin-top: 1.4rem;
          cursor: pointer;
        }
      }

      .Accounting-Form-Row {
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between !important;
      }

      .FormControl-Wrapper {
        height: unset;
      }

      p {
        padding-top: 1rem;
        font-size: $fontSizeMediumSmall;
      }
    }

    &-Subscription,
    &-ProjectCount {
      .ant-input,
      .ant-input-affix-wrapper {
        width: 9.5rem;
        max-height: 3.4rem;
        margin-right: 2rem;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .ant-input-affix-wrapper > .ant-input {
        margin-right: 0;
      }

      .ant-picker {
        margin-left: 5.4rem;
        width: 30rem;
        height: 4rem;
      }

      label {
        width: 16rem;
        padding-top: 0.8rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        .ant-picker {
          width: 100% !important;
        }

        .ant-row > .FormControl-Wrapper {
          grid-template-columns: repeat(2, 1fr);
        }

        label {
          min-width: 10rem;
        }
      }
    }

    &-ScaniflyInfo {
      .Accounting-Form-Row {
        grid-template-columns: 1fr;

        .FormControl-Wrapper {
          height: unset;
          display: flex;
          flex-direction: row;
        }

        label {
          width: 50%;
          padding-top: 1.2rem;
        }

        .css-dyr6gj-container {
          width: 26rem;
        }

        @media only screen and (max-width: $breakpoint-md) {
          .css-dyr6gj-container {
            width: 100%;
          }

          label {
            width: 20rem;
          }
        }
      }
    }

    &-DesignServices {
      .Accounting-Form-Row {
        grid-template-columns: 1fr;

        .FormControl-Wrapper {
          height: unset !important;
          display: flex;
          flex-direction: row;

          > input,
          span:last-child {
            width: 25%;
          }
        }

        @media only screen and (max-width: $breakpoint-md) {
          .FormControl-Wrapper {
            > input,
            span:last-child {
              width: 50%;
            }
          }
        }

        label {
          width: 50%;
          padding-top: 1.2rem;
        }

        .css-dyr6gj-container {
          width: 26rem;
        }

        @media only screen and (max-width: $breakpoint-md) {
          .css-dyr6gj-container {
            width: 50%;
          }

          label {
            width: 20rem;
          }
        }
      }
    }

    &-Subscription {
      .Accounting-Form-Row {
        grid-template-columns: repeat(4, 1fr);
        margin: 3rem 0;
        align-items: center;

        .FormControl-Wrapper {
          height: unset !important;
          display: flex;
          flex-direction: row;
          margin-bottom: unset;
        }

        > label,
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .Accounting-Form-Total-Fee {
    margin: 3rem 0 2rem 0;
    display: flex;
    justify-content: space-between;

    > p {
      margin-bottom: 0;
    }
  }

  &-SubHeader {
    width: 85%;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 80%;

      p {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-xl) {
      width: 68%;
      justify-content: flex-start;
    }

    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;

      .ant-input-affix-wrapper {
        width: 100% !important;
        margin-left: 1.2rem;
      }
    }
  }

  &-Table {
    margin-bottom: 0;

    .ant-table-header {
      table:first-of-type {
        table-layout: unset !important;
      }
    }

    .ant-table-cell {
      white-space: pre-wrap;
    }

    &-Title {
      font-weight: bold;
    }

    &-Row {
      &--Light {
        background-color: $white !important;
      }

      &--Dark {
        background-color: $background !important;
      }
    }
  }

  &-TabWrapper {
    position: absolute;
    z-index: 1;
    right: 4.2rem;

    .ant-radio-button-wrapper {
      font-size: $fontSizeMediumSmall;
      height: 3.8rem;
      padding: 0.25rem 1.6rem;
      margin-top: 3.3rem;
      border: 0.1rem solid $neutral-gray;
      color: $dark-gray;

      &:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }

      &:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }

      &-checked {
        background: $main-blue !important;
        border-color: $main-blue;
      }
    }
  }

  &-StatusLink {
    font-weight: $fontWeightSemiBold;
  }

  &-LightBlue {
    color: $light-blue;
  }

  &-Orange {
    color: $orange;
  }

  &-Green {
    color: $green;
  }

  &-Red {
    color: $red;
  }

  &-Purple {
    color: $purple;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      text-align: start !important;
    }
  }
}
