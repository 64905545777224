@import 'font-weights';
@import 'font-sizes';

.MainTitle {
  font-weight: $fontWeightBold;
  font-size: $fontSizeExtraLarge;
  line-height: 3.8rem;
  letter-spacing: 0.02rem;
  color: $dark-gray;
  margin-bottom: 0;
}
