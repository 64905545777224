@import 'styles/helpers/';
@import 'styles/typography';

.uppy {
  &-Dashboard {
    &-close {
      display: none;
    }

    &-isFixed {
      overflow: auto;
    }

    &-inner {
      font-family: $fontFamily;
      border-radius: $borderRadiusSm;
      min-width: 72rem;
      height: 59rem;

      &Wrap {
        border-radius: $borderRadiusSm;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        min-width: 75%;
        margin-top: 15%;
      }
    }

    &-Item {
      color: $label-gray;

      &-action {
        &--remove {
          svg path:first-of-type {
            fill: $dark-gray;
          }
        }
      }

      &-previewInnerWrap {
        border-radius: $borderRadiusXXs;
      }

      &-statusSize {
        display: none !important;
      }

      &-status {
        display: none;
      }

      &-progressIcon {
        &--bg {
          fill: transparent !important;
        }
      }
    }

    &Tab {
      &-btn {
        &:hover {
          background-color: $blue-gray-highlight;
        }
      }

      &-name {
        color: $label-gray;
        font-size: 1.4rem !important;
        margin-top: 1.2rem !important;

        @media only screen and (max-width: $breakpoint-sm) {
          margin-top: 0 !important;
        }
      }

      button[aria-controls='uppy-DashboardContent-panel--GoogleDrive'] {
        &::after {
          content: 'BETA: Max 250 images';
          background: #d8e0fd;
          color: $main-blue;
          font-size: $fontSizeExtraSmall;
          font-weight: 600;
          letter-spacing: 0.05rem;
          border-radius: 1.2rem;
          padding: 0.3rem 0.8rem;
          max-width: fit-content;
          max-height: 2.4rem;
          margin-top: 1.4rem;

          @media only screen and (max-width: $breakpoint-sm) {
            margin-top: 0;
            margin-left: 1.2rem;
          }
        }
      }
    }

    &-AddFiles {
      &-list {
        padding-top: 2.5rem !important;
        gap: 4.2rem;

        @media only screen and (max-width: $breakpoint-sm) {
          gap: 0.4rem;
        }
      }

      &-title {
        color: $dark-gray;
      }
    }

    &-browse {
      color: $main-blue;
    }

    &-overlay {
      background-color: $black-twenty-five-percent !important;
    }
  }

  &-DashboardContent {
    &-addMore,
    &-back {
      color: $main-blue;
    }
  }

  &-Provider {
    &Browser {
      &Item {
        &-checkbox--is-checked {
          background-color: $main-blue !important;
          border-color: $main-blue !important;
        }
      }

      &-filter {
        input {
          width: calc(100% - 5.2rem);
          padding: 0.1rem 0.8rem;
          margin: 0 1.2rem;
        }
      }

      &-userLogout {
        color: $main-blue;
      }
    }

    &IconBg {
      fill: $light-blue;
    }

    &-breadcrumbsIcon svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &-c-btn {
    &-link {
      color: $dark-gray !important;
    }

    &-primary {
      background-color: $main-blue !important;
      border-radius: $borderRadiusXs;
      padding: 1.5rem auto;
    }
  }

  &-StatusBar {
    &-actionBtn {
      &--retry {
        padding-top: 0.25rem;
      }

      &--upload {
        background-color: $main-blue !important;
        border-radius: $borderRadiusXs;
        padding: 1.5rem auto;
      }
    }

    &-actionCircleBtn {
      & circle {
        fill: $label-gray;
      }
    }

    &-details {
      display: none;
    }

    &-progress {
      background-color: $main-blue;
    }

    &-spinner {
      fill: $main-blue;
    }
  }
}

.UploadOverlay {
  .uppy {
    &-StatusBar {
      font-family: 'Poppins', Helvetica, sans-serif;
      z-index: 5;
      background-color: transparent;
      margin-top: 47vh;
      margin-left: -4.5vw;
      max-width: 50%;
      cursor: default;

      // On Upload error there is a white bar that pops up. This prevents that
      &::before {
        display: none;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        margin-left: -15vw;
        max-width: 62%;
      }

      &-actions {
        margin: 26.5vw;
        margin-bottom: 0;
      }

      &-progress {
        background-color: $main-blue;
        height: 2rem;
        border-radius: $borderRadiusSm;
        margin: 3rem 26vw;
        margin-bottom: 0;
      }

      &-spinner {
        display: none;
      }

      &-statusPrimary {
        margin-left: 26.5vw;
        font-size: 2.5rem;
        color: $white;
        margin-top: -7.75vh;
        // width: 100vw;
      }

      &-statusPrimary::before {
        content: 'Hang tight! ';
        // width: 100vw;
        margin-left: -0.8vw;
        margin-right: 1vw;
      }

      & .uppy-c-icon {
        display: none;
        vertical-align: middle;
        margin: 0 1rem;
        transform: scale(1.4);
      }

      &-statusSecondary {
        display: none;
        margin-left: 25.7vw;
        font-size: 2rem;
        color: $white;
        margin-top: 1vh;
        // width: 100vw;
      }

      &-additionalInfo {
        display: none;
      }

      &-statusIndicator {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &.is-uploading {
        &::before {
          display: none;
        }
      }
    }

    &-Informer {
      top: 3.25rem;
    }
  }
}

.ProjectCategory {
  .uppy {
    &-DragDrop {
      &-container {
        border-radius: 10px;
        border: 1px dashed $label-gray;
        cursor: pointer;
      }

      &-inner {
        height: 8rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2.5px;
      }

      &-label {
        color: $dark-gray;
        font-size: $fontSizeMedium;
        vertical-align: middle;
        font-family: 'Poppins', Helvetica, Arial, sans-serif;

        &::before {
          display: block;
          content: url('../../assets/icons/arrow-circle.svg');
          width: 2rem;
          height: 2rem;
          margin-top: 0.2rem;
          float: left;
          margin-right: 0.8rem;
        }
      }

      &--isDraggingOver {
        border-color: $main-blue;
        transition: 300ms ease-in-out;

        .uppy-DragDrop-label {
          color: $main-blue;

          &::before {
            content: url('../../assets/icons/arrow-circle-blue.svg') !important;
          }
        }
      }

      &-browse {
        color: $main-blue;
      }

      &-arrow {
        display: none;
      }
    }

    &-Root {
      margin: 0.65rem 0;
      cursor: pointer;
      width: 100% !important;

      &:hover,
      &:active {
        border-color: $main-blue;

        .uppy-DragDrop-label {
          color: $main-blue;

          &::before {
            content: url('../../assets/icons/arrow-circle-blue.svg');
          }
        }
      }
    }
  }
}
