@import 'styles/helpers';
@import 'styles/typography';

.FilterHelpers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    display: block;
  }

  &-ButtonWrapper {
    display: flex;

    .Button--Blue {
      > button {
        height: 3rem !important;
        box-shadow: none;
        text-shadow: none;
        background-color: $light-gray !important;
        border: 0.1rem solid $neutral-gray !important;
        color: $white !important;

        span {
          margin-left: 1rem;
        }
      }
    }
  }

  &-ViewSwitch-Wrapper {
    display: flex;
    margin-right: 0.5rem;
    margin-bottom: -0.6rem;
    margin-left: 1rem;

    @media only screen and (max-width: $desktop) {
      display: none;
    }

    .ViewSwitchButton {
      padding: 0;
      border: none;
      cursor: pointer;
      background: transparent;

      svg {
        path {
          fill: $label-gray;
        }
      }

      &:first-child {
        margin-right: 0.8rem;
      }

      &:disabled {
        cursor: initial;

        svg {
          path {
            fill: $main-blue;
          }
        }
      }
    }
  }

  &-Switch {
    display: flex;
    align-items: center;
  }

  &-Sort {
    position: relative;

    svg path {
      fill: $gray;
    }

    &-Arrow {
      display: inline-block;
      margin-right: 0.5rem;
      max-height: 2.5rem;
      width: 1rem;
      font-size: $fontSizeMedium;
      cursor: pointer;
      vertical-align: middle;

      svg path {
        fill: $main-blue;
      }

      &--Desc {
        transition: transform 0.3s ease-in-out;
        transform: rotateX(180deg);
      }

      &--Hidden {
        visibility: hidden;
      }
    }

    & > .Button--Filter {
      &:hover,
      &:focus,
      &:active {
        svg path {
          fill: $main-blue;
        }
      }

      margin-bottom: 0 !important;
      margin-left: 1rem !important;
      margin-right: 0 !important;
    }

    &-Dropdown {
      position: absolute;
      display: flex;
      top: 3.8rem;
      right: 0.8rem;
      border: 0.1rem solid $neutral-gray;
      background-color: $white;
      border-radius: $borderRadiusXs;
      z-index: 11;
      padding: 1.5rem;
      width: 100%;
      max-width: 15rem;

      @media only screen and (min-width: $breakpoint-lg) {
        min-width: 15rem;
      }
    }

    &-Items {
      margin-left: 0.5rem;
    }

    &-Item {
      cursor: pointer;

      span {
        font-weight: $fontWeightNormal;
        font-size: $fontSizeExtraSmall;
        color: $dark-gray;
        text-transform: capitalize;
      }

      &--Active {
        color: $main-blue !important;
        font-weight: $fontWeightBold !important;
      }
    }
  }

  &-All {
    display: flex;
    align-items: center;

    & > span,
    label {
      margin-right: 1rem;
      font-size: $fontSizeMediumSmall;
      font-weight: $fontWeightNormal;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }

  &-CSVExportButton {
    height: 3rem !important;

    span {
      margin-left: 1rem;
    }
  }
}

.EditFolders {
  margin-top: 1rem;

  &-Button.ant-btn {
    font-size: $fontSizeExtraSmall;
    font-weight: $fontWeightNormal;
  }
}
