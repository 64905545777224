@import 'styles/helpers';

.DeleteDesignModal {
  &-Description {
    margin: 1.6rem 0 3rem 0;
  }

  &-Buttons {
    &-Wrapper {
      display: flex;
      justify-content: flex-end;

      > :first-child {
        margin-right: 1.6rem;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        justify-content: center;
      }
    }
  }
}
