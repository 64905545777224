@import 'styles/helpers';
@import 'styles/typography';

.InfoViewChart {
  font-family: $fontFamily;
  text-align: left;

  .recharts-cartesian-axis-tick {
    font-size: $fontSizeMediumSmall;
  }

  .recharts-tooltip-label {
    color: $dark-gray;
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: $white-ten-percent;
  }

  &-Legend {
    display: flex;
    align-items: center;
    margin-bottom: 2.7rem;

    &-Bar {
      width: 3rem;
      height: 1.2rem;
      background: $main-blue;
      border-radius: $borderRadiusSm;
      margin: 0 0.8rem;

      &-Consum {
        margin-left: 2.6rem;
        background: $teal;
      }
    }
  }

  &-Instruction {
    position: absolute;
    text-align: center;
    width: 90%;
    z-index: 1;
    padding: 8.5rem 14rem;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding: 7.5rem;
      width: 86%;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      padding: 7.5rem 0;
      margin: 0% 18%;
      width: 54%;
    }
  }
}
