.MapStyleControl {
  position: absolute;
  bottom: 5rem;
  left: 2.75rem;
  width: 100px;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
