@import '../../../helpers/index';

.ant-select {
  &-item-option {
    padding: 1.2rem;
    color: $gray;
    font-size: $fontSizeMedium;

    &-selected {
      color: $dark-gray !important;
      font-weight: $fontWeightSemiBold !important;
    }
  }

  &-dropdown {
    border-radius: $borderRadiusSm;
    box-shadow: 0 0.8rem 4rem rgba($black, 0.08);
    border: 0.1rem solid $neutral-gray;
  }
}

.Auth {
  .ant-select {
    width: 40rem;
    font-size: $fontSizeMedium;
    border-color: $neutral-gray;

    &:not(:disabled) {
      color: $dark-gray;
    }

    &-arrow {
      transition: transform 0.3s;
      margin-right: 0.7rem;

      svg path {
        fill: $dark-gray;
      }
    }

    &-open {
      > .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    &-selector {
      border-radius: $borderRadiusXs !important;
      height: 5.2rem !important;
      display: flex;
      justify-content: center;
    }

    &-selection-item {
      top: 1rem;
    }

    &-selection-search-input {
      margin-top: 1rem !important;
    }

    &-selection-placeholder {
      padding: 1.1rem 0.7rem !important;
      color: $label-gray;
    }
  }
}
