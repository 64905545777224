@import 'styles/helpers';
@import 'styles/typography';

aside.emoji-picker-react {
  position: absolute;
  margin-top: -39rem;
  float: right;
  right: 3.2rem;
  border: 0.1rem solid $neutral-gray;
  font-family: $fontFamily;

  @media only screen and (max-width: $breakpoint-lg) {
    margin-top: -42rem;
  }

  .active-category-indicator-wrapper .active-category-indicator {
    background: $light-blue !important;
    margin-left: 0.125rem;
  }
}

.ant-modal {
  aside.emoji-picker-react {
    margin-top: -44rem;
    margin-right: 1rem;
    box-shadow: none;
  }
}
