@import 'src/styles/helpers/';
@import 'src/styles/typography';

.Thumbnail {
  width: 14.5rem;
  height: 12.65rem;
  border-radius: $borderRadiusSm !important;
  margin-right: 2rem;
  margin-bottom: 2rem;

  &-Loader {
    margin: 0 1.2rem 0 0.4rem;
  }

  &-Spinner {
    position: absolute;
    top: 5rem;
  }

  .ant-card-cover {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background-color: $blue-gray;
    border-radius: $borderRadiusSmTop;

    img,
    svg {
      height: 8.6rem;
      border-radius: $borderRadiusSmTop;
      object-fit: cover;
    }
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: -11rem;
    position: absolute;
    right: 0.5rem;
  }

  &-Name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 13rem;

    .ant-spin {
      margin-top: 0 !important;
    }
  }

  .ant-card {
    border: 1px solid $neutral-gray;
  }

  &-Wrapper {
    position: relative;
    margin: 0 0.85rem 1.8rem 0.85rem;
    box-shadow: $card-shadow;
  }

  &--Hidden {
    display: none;
  }

  &-Checkbox {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
  }

  &--List {
    width: 100%;
    height: auto;
    margin-bottom: -0.25rem !important;

    & > div {
      margin-left: 3rem;
      width: 97.5%;

      .Thumbnail-Checkbox {
        left: -3rem;
        top: 1.125rem;
      }
    }

    & .ant-card {
      width: 97%;
      height: 4.2rem;
      padding: 0.5rem;
      background: $white;

      @media only screen and (max-width: $breakpoint-sm) {
        width: calc(100% - 3rem);
      }
    }

    & .ant-card-body {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }

      .Button--Filter {
        max-width: 1rem;
        max-height: 3rem;
      }

      & .Thumbnail-Name {
        margin-top: -0.25rem;
        max-width: 30rem;

        @media only screen and (max-width: $breakpoint-md) {
          max-width: 15rem;
        }

        & svg {
          width: 1.5rem;
          color: $dark-gray;
          margin: -0.125rem 1.5rem 0 0.5rem;
        }

        & span {
          vertical-align: top;
        }
      }

      & .Thumbnail-Buttons {
        margin-top: -1rem;
        right: 1rem;
      }
    }

    &:first-of-type {
      & .Thumbnail-Name {
        & svg {
          width: 2.75rem;
          margin: -0.1rem 0.9rem 0 -0.15rem;
        }
      }
    }

    & .ant-card > .ant-card-cover {
      display: none;
    }

    & .Thumbnail-Loader {
      margin: 0.125rem 1.6rem 0 0.5rem;
    }
  }

  &-List {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 39rem);
    margin-bottom: 2rem;
    margin-right: -1.6rem;

    &--Scroll {
      margin-right: -3.2rem;
    }

    &--GridView {
      justify-content: center;
    }

    &--DroneImages {
      height: calc(100vh - 67.5rem);

      @media only screen and (max-width: $breakpoint-xl) {
        height: calc(100vh - 70rem);
      }

      @media only screen and (max-width: $breakpoint-md) {
        height: calc(100vh - 57.5rem);
      }

      @media only screen and (max-height: 900px) {
        height: 28rem;
      }
    }

    &--Empty {
      height: calc(100vh - 38.5rem);
    }

    &--Processed {
      height: calc(100vh - 50.5rem);

      @media only screen and (max-width: $breakpoint-md) {
        height: calc(100vh - 75rem);
      }

      // TODO: Remove when drone data score feature flag is removed
      &--WithoutDroneDataScore {
        height: calc(100vh - 32rem);
      }

      &--DroneDataUnavailable {
        height: calc(100vh - 41rem);
      }
    }
  }

  .ant-card-body {
    padding: 1rem;
    font-size: $fontSizeExtraSmall;
    color: $label-gray;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .Button--Filter {
      max-width: 1rem;
      max-height: 3rem;

      svg {
        margin-left: -0.85rem;
        margin-top: 0.065rem;
        margin-right: -0.85rem;
      }

      &:hover,
      &:active,
      &:focus {
        svg path {
          fill: $main-blue;
        }
      }

      &--Disabled {
        cursor: not-allowed;
        background-color: $background;
        border: 0.1rem solid $neutral-gray;

        svg path {
          fill: $mid-gray !important;
        }
      }

      &:nth-child(1) {
        margin-right: 1rem;
      }
    }
  }
}
