@import 'styles/helpers';

.AccountProfileAvatar {
  width: 10rem !important;
  height: 10rem !important;
  background-color: $blue-gray;
  border: 0.2rem solid $yellow;
  border-radius: 50%;
  margin-bottom: 1.6rem;

  &-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 6.07rem;
  }

  &-UploadInput {
    display: none;
  }

  &-Spinner {
    position: absolute;
    margin-left: 42.75%;
    margin-top: 4rem;
    z-index: 2;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-left: 39.5%;
    }
  }

  &-Button {
    margin-right: 1.6rem;

    &-Wrapper {
      display: flex;
      align-items: center;
      padding-left: 3.5rem;
      justify-content: center;
      margin-top: 1.2rem;
    }
  }
}
