@import 'styles/helpers/colors';

.SignUpError {
  text-align: center;
  margin-bottom: 4rem !important;

  .ant-alert-message {
    color: $red;
  }
}
