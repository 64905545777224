@import 'styles/helpers';
@import 'styles/typography';

.EditFoldersModal {
  .ant-modal-title {
    color: $dark-gray;
    font-size: $fontSizeLarge;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 2.4rem;
  }

  &-CommonGroup {
    font-weight: $fontWeightBold;
    color: $label-gray;
    display: inline-block;
    margin-bottom: 0.9rem;

    span {
      color: $dark-gray;
    }
  }

  &-Tags {
    margin-bottom: 2rem;
  }

  &-Tag {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    .TagContent {
      display: flex;
      align-items: center;
      height: 2.2rem;
      padding: 0;
    }

    &-Text {
      width: calc(100% - 2rem);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-FolderSelect {
    height: 4.2rem;
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;

    > :first-child {
      margin-right: 0.8rem;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      justify-content: center;
    }
  }

  &-FolderTag {
    height: 3.8rem;
    padding-top: 1.2rem;
  }

  .FolderIcon {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.9rem;
    margin-bottom: 0.2rem;

    path {
      fill: $white;
    }
  }
}
