.ant-menu-item {
  &-selected,
  &-active {
    background: inherit !important;

    &::after {
      opacity: 0 !important;
    }
  }
}
