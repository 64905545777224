@import 'styles/helpers/';
@import 'styles/typography';

.PreliminaryDesignForm {
  font-size: $fontSizeSmall;
  color: $label-gray;
  margin: 0 auto;

  &-RadioButtons {
    margin-bottom: 1rem;
    max-width: 50%;

    .radio-button-wrapper {
      display: flex;
      flex-direction: column;

      .ant-radio-wrapper {
        font-size: $fontSizeSmall;
        color: $label-gray;
        margin: 0.15rem 0;
      }
    }
  }

  &-CustomSelect {
    max-width: 50%;
  }

  .additional-info {
    font-size: $fontSizeExtraExtraSmall;
    margin-top: 2rem;

    .additional-info-title {
      font-size: $fontSizeSmall;
    }

    input {
      margin-top: 0.5rem;
    }
  }
}
