@import 'styles/helpers';
@import 'styles/typography';

.IntegrationsSettingsModal {
  position: relative;
  width: 80rem !important;

  .ant-modal-content {
    padding: 2.5rem !important;

    .ant-modal-header {
      margin-bottom: 1rem;
      border-bottom: 1px solid $neutral-gray;

      .header-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 1.2rem;

        svg {
          margin-right: 1rem;
        }
      }
    }

    .ant-modal-body {
      min-height: 25rem;
      max-height: 45rem;
      overflow-y: auto;

      .DirectorySelect {
        .breadcrumbs {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 3rem;
          color: $gray;
          border-bottom: 1px solid $neutral-gray;
          font-size: $fontSizeExtraSmall;

          div {
            height: 3rem;

            svg {
              margin-right: 1rem;
              margin-left: 1.4rem;
              margin-bottom: -0.5rem;
              color: $light-gray;
            }
          }
        }

        .message {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3rem 4rem;
          border-radius: 4px;
          border: 1px solid $neutral-gray;
          background-color: #efefef;
          padding: 2rem;
        }

        .spinnerContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3rem 4rem;
        }

        .directories {
          display: grid;
          grid-template-columns: 20% 20% 20% 20%;
          justify-items: center;
          align-items: center;
          row-gap: 2rem;
          margin-top: 2rem;
          column-gap: 4.1rem;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 16rem;

          .directory {
            width: 100%;

            button {
              width: 17.5rem;
              border-radius: 4px;
              background-color: $white;
              padding: 0.8rem 1.5rem;
              font-size: $fontSizeSmall;
              border: 1px solid $neutral-gray;
              cursor: pointer;

              div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;

                span {
                  margin-left: 1rem;
                }

                svg {
                  width: 18%;
                  height: 18%;
                  margin-right: 1rem;
                }

                div {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .footer {
          display: flex;
          position: absolute;
          bottom: 1rem;
          left: 2.5rem;
          height: 4rem;
          width: 95%;

          .select-back {
            align-items: flex-start;
            width: 40%;
          }

          .create-directory {
            align-items: flex-end;
            width: 60%;
            justify-content: flex-end;
            text-align: right;

            input {
              width: 25rem;
              margin-right: 1rem;
            }
          }

          button {
            margin-right: 1rem;
          }
        }
      }
    }

    .close-modal-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
}
