@import 'styles/helpers';
@import 'styles/typography';

.ProjectInfo {
  position: relative;
  width: 59.2rem;
  padding: 0 3.2rem 3.2rem;
  height: 100%;

  @media only screen and (max-width: $breakpoint-xl) {
    width: 100%;
  }

  &-TeammatesContainer {
    border-bottom: 0.1rem dotted $label-gray;
    margin-bottom: 2.9rem;
  }

  &-SelectWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2.9rem;
  }

  &-Input {
    margin-bottom: 1.6rem !important;
    margin-right: 1.8rem !important;

    .ant-select-selector {
      height: 4.2rem !important;
      display: flex;
      align-items: center;
    }
  }

  &-Label {
    color: $label-gray;
    font-size: $fontSizeMediumSmall;
  }

  &-ProjectName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 1rem;
  }

  .FolderIcon {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.9rem;

    path {
      fill: $white;
    }
  }

  .XIcon {
    width: 1.1rem;
    height: 1rem;
    margin: 0 0.5rem 0 0.9rem;
    cursor: pointer;

    path {
      fill: $white;
    }
  }

  .TagContent {
    display: flex;
    align-items: center;
    line-height: 1rem;
    margin: 0.4rem 0;
    min-height: 1.8rem;
    color: $white;
  }

  &-Buttons {
    display: flex;
    justify-content: flex-end;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      bottom: 3.2rem;

      svg {
        margin-top: 0.8rem;
      }

      .ant-btn {
        height: 4.2rem;
      }

      > button:nth-child(2) {
        margin-left: 1.5rem;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        position: relative;
        margin-top: 5rem;
        bottom: 0;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;
        width: 100%;

        button {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        > button:nth-child(2) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }
    }

    &-SubmitIcon--Hidden {
      display: none;
    }
  }

  &-Spinner {
    margin-bottom: 2.9rem;
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
    margin-top: -0.8rem;
  }
}
