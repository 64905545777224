@import 'styles/helpers';
@import 'src/styles/typography';

.MoreFilter {
  padding: 0 1.6rem;

  &-Item {
    margin: 2rem 0;

    > .MultiCheck {
      margin-top: 1rem;
    }
  }

  &-Label {
    display: flex;
    gap: 1rem;
    font-weight: $fontWeightBold;
    font-size: $fontSizeMedium;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  &-Select {
    width: 100% !important;
    margin-top: 0.8rem !important;

    .ant-select {
      &-selector {
        min-height: 4rem !important;
        font-size: $fontSizeMedium;
        color: $dark-gray;

        .ant-tag {
          text-align: center;
          display: flex;
          align-items: center;
          margin-bottom: 0;

          &-close-icon {
            display: flex;
            align-items: center;
            margin: 0 0 0.2rem 0.6rem;

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }

  &-Icon {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }
}
