@import 'styles/helpers';

.AccountProfile {
  &-Form {
    &-Row {
      display: grid !important;
      grid-template-columns: 1fr 1fr;

      > :not(:last-child) {
        margin-right: 0.6rem;
        margin-bottom: 4rem;

        @media only screen and (max-width: $breakpoint-md) {
          margin-right: 0;
        }
      }

      .css-dyr6gj-container > div {
        min-height: 4.2rem;
      }
    }
  }

  &-Button {
    margin-left: 1.6rem;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10.4rem;

      .ant-card-body {
        border: 0.1rem solid red !important;
      }
    }

    &-Icon {
      &--Hidden {
        display: none;
      }
    }
  }
}
