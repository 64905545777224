@import 'styles/helpers';
@import 'styles/typography';

.ProjectTypeFilter {
  margin-bottom: 1.8rem;

  .ant-radio-group {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;

    @media only screen and (max-width: 1320px) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      flex-wrap: wrap;
    }
  }

  &-Header {
    font-weight: $fontWeightSemiBold;
    color: $dark-gray;
    font-size: $fontSizeMedium;
    margin: 2rem 0 1.25rem 0;
    display: flex;

    p {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }

  &-Button {
    &.ant-radio-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: fit-content;
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusXs !important;
      line-height: 1.8rem;
      text-align: center;
      font-size: $fontSizeMediumSmall;
      padding: 0.75rem 2rem;
      background: transparent;
      color: $dark-gray;

      &-checked {
        background: $main-blue !important;
        color: $white;
      }

      &:hover {
        color: $main-blue;
      }

      &::before {
        padding: 0;
        background-color: transparent !important;
      }
    }
  }

  &-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-ButtonWrapper {
    display: flex;
  }
}
