@import 'styles/helpers/colors';
@import 'styles/typography';

.BetaTag {
  background: #d8e0fd;
  color: $main-blue;
  font-size: $fontSizeExtraSmall;
  font-weight: 600;
  letter-spacing: 0.05rem;
  border-radius: 1.2rem;
  padding: 0.3rem 0.8rem;
  max-width: fit-content;
  max-height: 2.4rem;
}
