@import 'styles/helpers';
@import 'src/styles/typography';

.Card {
  &--Big {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $neutral-gray;
    min-height: 61.6rem;
    width: 100%;

    > .ant-card-body {
      padding: 4.77rem 3.2rem 3rem;
    }
  }

  &--Small {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $neutral-gray;
    min-height: 30rem;
    width: 100%;

    > .ant-card-body {
      padding: 4.77rem 3.2rem 3rem;
    }
  }
}
