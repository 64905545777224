.Auth {
  .Form {
    &-Input {
      &-Error {
        border-color: $red;
      }
    }

    &-Error {
      max-width: 100%;
      color: $red;
      line-height: 1.2rem !important;
      font-size: $fontSizeExtraSmall !important;

      &--Short {
        max-width: 14rem;
      }

      &--Medium {
        max-width: 23.6rem;
      }

      &--Long {
        max-width: 40rem;
      }
    }
  }
}
