@import 'styles/helpers';
@import 'styles/typography';

.DroneDataScoreUpgradeMessageWrapper {
  position: relative;
  width: 100%;

  .UpgradeMessage {
    display: flex;
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.94);
    backdrop-filter: blur(1.5px);

    &-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $fontWeightSemiBold;
        color: $dark-gray;

        span {
          margin-left: 1.5rem;
          font-size: 2rem;
        }
      }

      .chip-container {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          padding: 5px 8px;
          margin-right: 1.5rem;
          color: $white;
          font-size: $fontSizeExtraExtraSmall;
          text-transform: uppercase;
          border-radius: 4px;
        }

        .professional {
          background-color: #5f6368;
        }

        .enterprise {
          background-color: #2e3134;
        }
      }
    }

    &-message {
      width: 100%;
      font-size: $fontSizeSmall;
      color: $label-gray;
    }

    &-contact {
      width: 100%;
      font-size: $fontSizeSmall;
      color: $label-gray;
    }

    &.ant-card {
      border: 0.1rem solid $neutral-gray;
      border-radius: $borderRadiusSm;

      > .ant-card-body {
        justify-content: space-between;
        flex-direction: column;
        padding: 1.4rem;
        display: flex;

        @media only screen and (max-width: $breakpoint-md) {
          flex-direction: column;
        }
      }
    }
  }

  .DroneDataScore {
    margin-bottom: 2rem;
    width: 100%;

    &-Unavailable {
      font-size: $fontSizeMedium;
      font-weight: $fontWeightSemiBold;
      color: $dark-gray;
      margin: 1rem 0;
    }

    &-Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: -2.4rem 1.4rem 0 0;

      @media only screen and (max-width: $breakpoint-md) {
        margin: -2.4rem 0 3.2rem 0;
      }
    }

    &-Stats {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 55%;
    }

    &-Title {
      color: $label-gray;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-Download {
      font-size: $fontSizeExtraExtraSmall;
      text-decoration: underline;
      color: $label-gray;
      margin-top: 0.75rem;
      cursor: pointer;
    }

    &-Tooltip {
      p:first-of-type {
        font-weight: $fontWeightBold;
      }

      a {
        color: $light-blue;
        text-decoration: underline;
        font-weight: $fontWeightSemiBold;
      }
    }

    p {
      margin-bottom: 0;
    }

    &.ant-card {
      border-radius: $borderRadiusSm;
      border: 0.1rem solid $neutral-gray;

      > .ant-card-body {
        padding: 1.4rem;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: $breakpoint-md) {
          flex-direction: column;
        }
      }
    }
  }
}
