.DesignSelection {
  &-ProjectTitle {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  &-MainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 7rem;
  }
}
