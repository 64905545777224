@import 'styles/helpers';
@import 'styles/typography';

.DateFilter {
  &-Selector {
    padding: 0 1.2rem;
    width: 100%;
    display: flex;
  }

  &-Selector > div > div {
    min-height: 4.2rem;
  }

  &-Selector-Label {
    padding: 0 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  &-Selector-Label > label {
    margin-left: 0.5rem;
  }

  &-Picker {
    border-radius: $borderRadiusXs !important;
  }

  &-Divider {
    margin: 1.6rem 0;
    border-top: 0.2rem solid $light-gray;
  }

  &-Content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & span {
      display: flex;
      align-items: center;
      font-size: $fontSizeExtraSmall;
      color: $label-gray;
      text-align: center;
      margin: 0 0.5rem;
    }
  }

  &-Input {
    width: 100% !important;
  }

  &-Dropdown {
    .ant-picker-panel-container {
      border-radius: $borderRadiusXs !important;
      border: 0.1rem solid $neutral-gray;
      overflow: hidden;
    }

    .ant-picker-cell-inner {
      border-radius: 50% !important;
      font-weight: $fontWeightSemiBold;
    }

    .ant-picker-cell-selected > .ant-picker-cell-inner {
      background: $main-blue !important;
      color: $white;
    }
  }
}
