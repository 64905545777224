@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/overwrites/buttons';

.ProjectCategory {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &-Label {
    color: $label-gray;
    font-size: $fontSizeMediumSmall;
  }

  &-Wrapper {
    padding: 2.4rem 3.2rem;
    width: 59.2rem;
    max-height: calc(100vh - 9.4rem);
    background: $background;
    z-index: 1;
    overflow-y: auto;

    @media only screen and (max-width: $breakpoint-lg) {
      margin: 0 !important;
      width: 100%;
      max-height: unset;
    }
  }

  &-Element {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 10rem;
  }

  &-ProjectName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 1rem;
  }

  &-Button {
    &-Icon {
      margin-right: 1rem;
    }

    &--Square {
      width: 3rem;
      height: 3rem !important;
      margin-left: 1.4rem;
      display: flex !important;
      align-items: center;
      justify-content: center;

      > svg {
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
      }

      &:disabled {
        background-color: $dark-gray !important;
        opacity: 0.3 !important;
        cursor: not-allowed;
      }
    }
  }

  &-Category {
    display: flex;
    align-items: center;
    background-color: $white;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    height: 3.4rem;
    padding: 0.9rem 1.5rem;
    border: 0.1rem solid $neutral-gray;
    border-radius: $borderRadiusXs;
    color: $dark-gray;
    margin-right: 2.1rem;

    &-Wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
    }

    &-Icon {
      width: 17px;
      height: 17px;
      margin-right: 0.8rem;

      path {
        fill: $dark-gray;
      }
    }

    &-Name {
      max-width: 27rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media only screen and (max-width: $breakpoint-sm) {
        max-width: 15rem;
      }
    }
  }

  &-Actions {
    &-Buttons {
      display: flex;
    }

    &-Wrapper {
      display: flex;
      justify-content: space-between;
      margin: 1.3rem 0 2.6rem 0;

      &--Hidden {
        display: none;
      }
    }

    &-SelectAll {
      &-Wrapper {
        display: flex;
        align-items: center;
        font-size: $fontSizeMediumSmall;
      }

      &-Title {
        margin-left: 1rem;
        color: $dark-gray;
      }

      &-Subtitle {
        margin-left: 0.7rem;
        color: $dark-gray;
      }
    }
  }

  &-Buttons {
    display: flex;
    justify-content: space-between;

    &-Wrapper {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 3.2rem;
      width: 52.8rem;

      span,
      span:hover {
        background-color: unset;
        border: unset;
      }

      .ant-btn {
        height: 4.2rem;
      }

      @media only screen and (max-height: 900px) {
        position: relative;
        bottom: 0;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
        position: relative;
        margin-top: 3.2rem;
      }

      @media only screen and (max-width: $breakpoint-md) {
        justify-content: center;
        flex-direction: column;

        button {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        flex-direction: column;

        > button:first-child {
          margin-right: 0;
          margin-bottom: 0.8rem;
        }
      }

      span {
        .ant-btn {
          @extend .Button--Blue;
        }
      }

      .ant-tooltip-disabled-compatible-wrapper,
      .ant-tooltip-disabled-compatible-wrapper:hover {
        cursor: not-allowed;
      }
    }
  }

  &-ModelArea {
    font-size: $fontSizeSmall;
    color: $label-gray;
  }

  &-SubmittedFor {
    display: flex;
    justify-content: flex-end;
    color: $label-gray;
    margin-top: -0.8rem;
    padding-bottom: 1rem;
    font-size: $fontSizeMediumSmall;
  }

  &-ErrorList {
    margin-top: 1rem;
  }

  .ant-spin-blur {
    overflow: visible;
  }

  &-TitleWrapper {
    display: flex;
    justify-content: space-between;
  }
}

.Thumbnail-Name .ant-spin.Thumbnail-Loader {
  margin-top: 0;
}
