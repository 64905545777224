@import 'styles/helpers';
@import 'styles/typography';

.Integrations {
  position: relative;
  margin: 0 auto;
  padding-top: 2rem;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4.3rem;
    margin-top: 9rem;
    margin-bottom: 3.4rem;
    width: 100%;

    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;

      .card {
        position: relative;
        box-sizing: border-box;
        width: 417px;
        height: 219px;
        background: $white;
        border: 1px solid $neutral-gray;
        border-radius: 8px;
        margin-bottom: 3rem;
        margin-right: 3rem;
      }
    }
  }
}
