@import 'styles/helpers';
@import 'styles/typography';

.Teammate {
  width: 21.6rem !important;
  height: 26rem !important;
  margin: 1rem !important;
  position: relative;

  &.ant-card {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $neutral-gray;
    padding: 2.29rem 2.4rem 0 2.4rem;
  }

  .ant-card-body {
    padding: 0;
  }

  &-Menu {
    position: absolute;
    right: 1.2rem;
    top: 0.8rem;
  }

  &-Avatar {
    display: block !important;
    margin: 0 auto 2.2rem !important;
    height: 8.4rem !important;
    width: 8.4rem !important;
    background-color: $blue-gray;
  }

  &-Name {
    font-weight: $fontWeightBold;
    line-height: 1.8rem;
    overflow-wrap: break-word;
    color: $dark-gray;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-Role {
    color: $label-gray;
    margin: 0.5rem 0;
    font-size: $fontSizeSmall;
    line-height: 1.9rem;
    text-transform: capitalize;
    overflow-wrap: break-word;
    max-width: 17rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-AdditionalInfo {
    color: $label-gray;
    font-size: $fontSizeExtraSmall;
    overflow-wrap: break-word;
    line-height: 2.1rem;
    max-width: 17rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &-Wrapper {
      position: absolute;
      bottom: 2.4rem;
    }
  }

  &--Horizontal {
    margin: 2.2rem 0 !important;
    padding: 1.2rem !important;
    width: 100% !important;
    height: 14rem !important;

    @media only screen and (min-width: $breakpoint-md) {
      max-height: 8.8rem;
      max-width: 37.2rem !important;
    }

    .ant-card-body {
      width: 100%;
      display: flex !important;
      flex-flow: column;
      align-content: flex-start;
      overflow: auto;
      align-items: center;

      @media only screen and (min-width: $breakpoint-md) {
        flex-wrap: wrap;
        max-height: 100%;
        align-items: flex-start;
      }
    }

    .Teammate {
      &-Avatar {
        margin: 0 1.2rem 0 0.4rem !important;
        height: 6rem !important;
        width: 6rem !important;
      }

      &-AdditionalInfo-Wrapper {
        display: none;
      }

      &-Name {
        margin-top: 1rem;
      }

      &-Role {
        color: $label-gray;
        margin-bottom: 0.5rem;
        line-height: 1.8rem;
        text-transform: capitalize;
      }
    }
  }
}
