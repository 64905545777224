.ant-btn {
  border-radius: $borderRadiusXs;
  border: none;
  letter-spacing: 0.02rem;
  height: 3.8rem;
  padding: 0 1.6rem;
  font-weight: $fontWeightSemiBold;

  &:hover {
    opacity: 0.85;
  }
}

.Button {
  &--Big {
    &.ant-btn {
      background-color: $main-blue;
      width: 40rem;
      height: 5.2rem;
      color: $white;
      font-size: $fontSizeMediumSmall;

      &:disabled {
        background: $light-gray;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &--Dark {
    background: $dark-gray;
    color: $white;
    font-size: $fontSizeMediumSmall;
    font-weight: $fontWeightSemiBold;

    &.ant-btn,
    &.ant-btn:focus,
    &.ant-btn:active {
      color: $white;
      background: $dark-gray;

      &:hover {
        color: $white;
      }
    }
  }

  &--Blue {
    background-color: $main-blue;
    color: $white;
    border: 0.1rem solid $neutral-gray;

    &:hover {
      background-color: $main-blue;
      color: $white;
      opacity: 0.85;
    }

    &:active,
    &:focus {
      background-color: $main-blue;
      color: $white;
    }

    &:disabled {
      background-color: $light-gray;
      border: 0.1rem solid $neutral-gray;
      color: $white;
    }
  }

  &--White {
    border: 0.1rem solid $neutral-gray;
    background-color: $white;
    color: $dark-gray;
  }

  &--Red {
    background-color: $red;
    color: $white;

    &.ant-btn:focus,
    &.ant-btn:active,
    &.ant-btn:hover {
      background: $red;
      opacity: 0.7;
      color: $white;
    }
  }

  &--Active {
    border: 0.1rem solid $main-blue !important;
    color: $main-blue;

    &:not(.FilterHelpers-Sort-Btn) svg path {
      fill: $main-blue;
    }

    &.FilterHelpers-Sort-Btn svg path {
      fill: $main-blue;
    }

    & span {
      color: $main-blue;
    }
  }

  &--Filter {
    border: 0.1rem solid $neutral-gray;
    background-color: $white;
    margin-bottom: 0.8rem;
    margin-right: 0.8rem;
    height: 3rem;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: $dark-gray;

    svg {
      margin-left: 1rem;
    }

    &:disabled svg path {
      fill: $mid-gray;
    }
  }

  &--Cancel {
    border: unset;
    background-color: unset;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: $gray;
    outline: none;

    &.ant-btn:focus,
    &.ant-btn:active,
    &.ant-btn:hover {
      border-style: outset;
      background-color: unset;
      border: unset;
      color: $dark-gray;
      text-decoration: underline;
    }
  }
}
