@import 'styles/helpers';
@import 'styles/typography';

.AccountPaymentsCreditCardForm {
  margin: 3rem 0 4.4rem 0;

  &-SubTitle {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    margin-top: 4.2rem;
    margin-bottom: 2.4rem;
    color: $dark-gray;
  }

  &-Row {
    > :not(:last-child) {
      margin-right: 0.6rem;

      @media only screen and (max-width: $breakpoint-md) {
        margin-right: 0;
      }
    }

    > :nth-child(n) {
      margin-bottom: 4rem;

      @media only screen and (max-width: $breakpoint-md) {
        margin-bottom: 3rem;
      }
    }

    .ant-input {
      color: $dark-gray;
    }

    .FormControl-Wrapper {
      &--Country {
        width: 100%;
        margin-bottom: 3rem;

        & > div > div {
          min-height: 4.2rem;
        }
      }

      &--Short {
        width: 12.6rem;

        .ant-input {
          width: 100% !important;
        }
      }

      &--Long {
        width: 100%;
        margin-bottom: 4rem;

        .ant-input {
          width: 100% !important;
        }
      }

      .ant-picker {
        width: 100%;
      }
    }
  }

  &-Button-Wrapper {
    margin-top: 4.6rem;
    display: flex;
    justify-content: flex-end;

    > :not(:last-child) {
      margin-right: 0.6rem;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      flex-wrap: wrap;

      > :not(:last-child) {
        margin-right: 0;
        margin-bottom: 0.6rem;
      }

      button {
        flex-basis: 100%;

        span:nth-of-type(2) {
          @include hidden-on-xxs;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
