@import 'styles/helpers';
@import 'styles/typography';

.NotificationBox {
  flex-grow: 1;

  .ant-alert {
    padding: 0.25rem 1.5rem;
    margin: 0 1rem !important;

    @media only screen and (max-width: $breakpoint-md) {
      margin: 1.5rem 0 0 !important;
    }
  }

  &-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Title {
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeExtraSmall;
    margin-bottom: 0;
  }

  &-Text {
    font-size: $fontSizeExtraSmall;
    margin-bottom: 0;
  }

  &-QuestionMarkIcon {
    margin: 0.5rem;
  }

  &-TickIcon {
    width: 1.25rem;
    height: 1.25rem;
    color: $green;
    margin-right: 0.75rem;
    vertical-align: middle;

    @media only screen and (max-width: $breakpoint-md) {
      width: 1.75rem;
    }
  }
}
