@import 'styles/helpers';
@import 'styles/typography';

.Auth {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 25rem;
  overflow-x: hidden;
  background-color: $white;

  &-Card {
    &.ant-card {
      border-radius: $borderRadiusSm;
      border: 0.1rem solid $neutral-gray;

      > .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8rem 5.6rem;
      }
    }

    &-Logo {
      width: 16.9rem;
      height: 4.5rem;
    }

    &-Title {
      font-size: $fontSizeExtraLarge;
      margin-bottom: 3.2rem;
      margin-top: 3.2rem;
      text-align: center;
      font-weight: $fontWeightBold;
      color: $dark-gray;

      &--Subtitle {
        margin-bottom: 2.4rem;
      }
    }

    &-Subtitle {
      font-size: $fontSizeMedium;
      line-height: 2.7rem;
      color: $gray;
      max-width: 72rem;
      font-weight: $fontWeightNormal;
      text-align: center;
      margin-bottom: 3.2rem;
    }
  }

  .ant-alert-success {
    height: 5rem;
    border: 0;
    border-radius: 1.2rem;
    background-color: $success-background;

    .ant-alert-message {
      display: flex;
      justify-content: center;
      color: $dark-gray;
    }
  }
}
