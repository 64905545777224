@import 'styles/helpers/colors';

.AccountLoginSecurityError {
  text-align: center;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;

  .ant-alert-message {
    color: $red;
  }
}
