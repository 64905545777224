@import 'styles/helpers';
@import 'styles/typography';

.AdjustDesignCredits {
  margin: 2.6rem 0 0.5rem -7%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $breakpoint-lg) {
    margin: 2.6rem 0 0 0;

    &.ant-row {
      display: block;
    }
  }

  &-Title {
    font-weight: $fontWeightSemiBold;

    & span {
      margin-left: 0.65rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }

  &-Input {
    > input {
      height: 3.8rem;
      width: 40rem;

      @media only screen and (max-width: $breakpoint-lg) {
        max-width: 100%;
      }
    }

    .ant-input {
      &::placeholder {
        color: $label-gray !important;
      }
    }
  }

  &-Button-Wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
