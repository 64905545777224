@import 'styles/helpers/';
@import 'styles/typography/';

.UpgradeModal {
  position: relative;

  .ant-modal-content {
    padding: 2.5rem !important;

    .close-modal-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
}
