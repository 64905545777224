@import 'styles/helpers';
@import 'styles/typography';

.ViewFormModal {
  h3 {
    font-weight: $fontWeightBold;
  }

  &-Wrapper {
    background-color: white;

    .ant-collapse-content-box {
      color: $gray !important;
      font-size: $fontSizeSmall;
      font-weight: $fontWeightSemiBold;
      max-width: 47rem;
      white-space: pre-line;
    }

    .ant-collapse-header {
      font-weight: $fontWeightSemiBold;
    }
  }

  &-UploadsWrapper {
    margin: 2rem 0;
  }

  &-DownloadButton {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border: 0.15rem solid $light-gray;
    border-radius: 1rem;
    color: $gray;
    font-weight: $fontWeightSemiBold;
    display: flex;
    align-items: center;
  }

  &-DownloadIcon {
    font-size: 3rem;
    margin-right: 1rem;
    padding: 0.5rem;
  }

  &-Thumbnail {
    width: 4rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
  }

  &-ButtonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
