.FormControl {
  &-Wrapper {
    height: 5.5rem;
    margin-bottom: 1.8rem;

    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
}
