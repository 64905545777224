@import 'styles/helpers';
@import 'styles/typography';

.CustomStep {
  &-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 3rem;

    .step-counter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1.5rem;

      .steps {
        font-size: 13px;
        line-height: 16px;
        color: $label-gray;
        margin-bottom: 1rem;
      }

      .form-title {
        font-weight: $fontWeightBold;
      }

      span {
        color: $main-blue;
      }
    }

    .rail {
      width: 100%;
      border: 2px solid $neutral-gray;
      border-radius: 5px;
      height: 0;
      position: relative;

      .progress {
        border: 4px solid $main-blue;
        border-radius: 5px;
        height: 0;
        position: absolute;
        top: -4px;
        left: -4px;
        padding: 0 2px;
      }
    }
  }
}
