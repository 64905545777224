@import 'styles/helpers';

.Sidebar-Header-Wrapper {
  margin-top: 1.5rem;

  .Menu-Item--expanded {
    padding-left: 0;
  }

  .Menu-Item-Child {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }

  .Menu-Item-Icon {
    width: 100%;
    height: 100%;
  }
}

.Sidebar-Header {
  &-Logo {
    margin: 0 1.2rem;

    &--Hidden {
      display: none;
    }
  }

  &-NewProject {
    margin: 1.5rem 0;

    &--Collapsed {
      margin-left: 1rem;
    }

    .Menu-Item--collapsed {
      margin: 0;
    }
  }
}

.Menu-Item-Icon {
  span {
    svg {
      .dollar-sign {
        fill: $dark-gray !important;
      }
    }
  }
}
