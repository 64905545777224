@import 'styles/helpers/';
@import 'styles/typography';

.CommentForm {
  font-size: $fontSizeSmall;
  color: $label-gray;
  margin: 0 auto;

  .additional-info {
    font-size: $fontSizeExtraExtraSmall;

    .additional-info-title {
      font-size: $fontSizeSmall;
    }

    textarea {
      margin-bottom: 2rem;
    }
  }
}
