@import 'styles/helpers';
@import 'styles/typography';

.ProjectTile {
  position: relative;

  &-Card {
    width: 25.2rem;
    height: 29.4rem;

    @media only screen and (max-width: $breakpoint-xs) {
      width: 24rem;
    }

    &:focus-visible {
      border: 0.1rem solid $main-blue;
    }
  }

  & > .ant-card {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $light-gray;
    overflow: hidden;

    & > .ant-card-cover {
      min-height: 16rem;
      max-height: 16rem;

      > img {
        min-height: 16rem;
        max-height: 16rem;
        object-fit: cover;
      }
    }

    &-hoverable {
      &:hover {
        border: 0.1rem solid $neutral-gray;
      }
    }

    & > .ant-card-body {
      padding: 1.7rem;
      width: 100%;
    }

    & .ant-card-meta {
      margin-top: -0.8rem;

      &-title {
        color: $dark-gray;
        font-weight: $fontWeightSemiBold;
        font-size: 1.85rem;

        div {
          max-width: calc(100% - 4.5rem);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  &-Folder {
    position: absolute;
    display: flex;
    bottom: 14rem;
    left: 0;
    right: 0;
    overflow: hidden;

    .ant-tag {
      display: flex;
      align-items: center;
      height: 2.2rem;
      margin: 0 1.7rem;
      padding: 0;
      max-width: calc(100% - 3.4rem);
    }

    &-Text {
      width: calc(100% - 2rem);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-Status {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 1;
    width: 18.75rem;
    top: 1.5rem;
    left: 4.7rem;

    .ant-tag {
      display: flex;
      margin-bottom: 0;
    }
  }

  &-Checkbox {
    position: absolute;
    top: 1.5rem;
    left: 1.7rem;
    z-index: 1;
  }

  &-Address {
    position: relative;
    color: $label-gray;
    font-size: $fontSizeSmall;
    margin-bottom: 0.8rem;
    max-height: 4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-Date {
    color: $dark-gray;
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: -0.25rem;
  }

  &-IconWrapper {
    display: flex;
    align-items: center;
  }

  &-Icon {
    height: 2rem;
    width: auto;
    margin-left: 0.5rem;

    &--White {
      color: $white;
    }

    &--Blue {
      color: $main-blue;
    }
  }

  &-TagIcon {
    height: 2rem;
    width: auto;
    margin-right: 0.5rem;
  }

  .DropdownMenu-Wrapper {
    padding: 1.5rem 1.9rem;
  }

  .FolderIcon {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.9rem;
    margin-bottom: 0.2rem;

    path {
      fill: $white;
    }
  }

  .TagContent {
    line-height: 1rem;
    color: $white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 0.6rem;
  }
}

.ProjectTile--List.ProjectTile {
  padding-left: 3.2rem;

  .ProjectTile {
    &-Card {
      height: 10.6rem;
      width: 100.2%;

      &.ant-card {
        display: flex;

        & > .ant-card-cover {
          min-height: 10.2rem;
          width: 10.2rem;

          > img {
            width: 10.5rem;
            min-height: 10.5rem;
          }
        }

        & > .ant-card-body {
          padding-top: 1.1rem;
          padding-bottom: 0;
          max-width: calc(100% - 10.2rem);
        }

        & .ant-card-meta {
          margin-top: 0;

          &-description {
            display: flex;
            flex-direction: column;
            height: calc(100% - 2rem);
            justify-content: space-evenly;
          }

          &-title {
            line-height: 2.5rem;
            margin-bottom: 0.4rem;
            max-width: calc(100% - 10rem);
          }

          &-detail {
            > div:not(:last-child) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-Folder {
      bottom: 0.8rem;
      right: 0;
      justify-content: flex-end;

      .ant-tag {
        max-width: 30%;
      }
    }

    &-Status {
      top: 1.3rem;
      right: 1.4rem;
      left: initial;
      gap: 0.75rem;
      justify-content: flex-end;
      width: inherit;

      .ant-tag {
        margin-right: 0;
      }

      .ant-tag-has-color {
        display: flex;
        align-items: center;
      }
    }

    &-MenuWrapper {
      right: 0.9rem;
      top: 0.9rem;
    }

    &-Checkbox {
      top: 4.1rem;
      left: -0.1rem;
    }

    &-Address {
      height: 3.4rem;
      margin-bottom: 0.5rem;
      margin-top: 0.2rem;
      line-height: 1.7rem;
      font-size: 1.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
