@import 'styles/helpers';

.ViewSwitchToggle {
  display: flex;
  align-items: center;

  &-Button {
    padding: 0;
    border: none;
    cursor: pointer;
    background: transparent;

    &:first-child {
      margin-right: 0.8rem;
    }

    svg {
      path {
        fill: $label-gray;
      }
    }

    &--Active {
      cursor: default;

      svg {
        path {
          fill: $main-blue;
        }
      }
    }

    button:disabled {
      background: transparent;
      border: none;
      padding: 0;
    }
  }

  &-Wrapper {
    display: flex;
    margin-right: 0.5rem;
    margin-bottom: -0.6rem;
  }
}
