@import 'styles/helpers';
@import 'styles/typography';

.AccountCompanyInfo {
  &-Form {
    &-Row {
      &:first-of-type {
        margin-bottom: 4rem;
      }

      & .FormControl-Wrapper {
        width: 100%;
      }

      margin-bottom: 2.4rem;
    }

    &-Address {
      margin-bottom: 4rem;

      & .FormControl-Wrapper {
        width: unset;
      }

      > :not(:last-child) {
        margin-right: 0.7rem;

        @media only screen and (max-width: $breakpoint-md) {
          margin-right: 0;
        }
      }
    }

    &-Wrapper {
      display: flex;
      align-items: flex-end;

      @media only screen and (max-width: $breakpoint-md) {
        flex-wrap: wrap;
      }
    }

    &-Button {
      margin-left: 0.8rem;
      height: 4.2rem !important;

      @media only screen and (max-width: $breakpoint-md) {
        margin-left: 0;
        margin-top: 1.6rem;
      }
    }
  }

  &-Select {
    width: 52.8rem !important;
  }

  &-Subtitle {
    font-size: $fontSizeExtraSmall;
    color: $label-gray;
    line-height: 1.6rem;
    margin-bottom: 3rem;
    margin-top: -2rem;
  }

  &-Button {
    margin-left: 1.6rem;

    &-Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-Icon {
      &--Hidden {
        display: none;
      }
    }
  }

  .ant-row {
    @media only screen and (max-width: $breakpoint-md) {
      display: block !important;
    }
  }
}
