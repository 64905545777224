@import 'styles/helpers/';
@import 'styles/typography/';

.FormDisplay {
  background-color: $white;
  width: 100%;

  .ant-divider {
    margin: 1.5rem 0 1rem 0 !important;
  }

  &-TitleAndButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 2.5rem 1rem 2.5rem !important;

    h2 {
      margin-bottom: unset;
    }

    .Button--Blue {
      svg {
        fill: $white;
        margin-right: 1rem;
      }
    }
  }

  &-Title {
    max-width: 30rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;

    @media only screen and (max-width: $breakpoint-xl) {
      max-width: 50rem;
    }
  }

  &-Label {
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeSmall;
    color: $gray;
  }

  form {
    padding: 0 2.5rem 1rem 2.5rem;

    @media only screen and (max-width: $breakpoint-xl) {
      padding-bottom: 2rem;
    }

    textarea {
      max-height: unset !important;
      min-height: 100% !important;
    }

    .FormDisplay-FieldWrapper {
      margin-bottom: 1.5rem;
    }

    .FormDisplay-TextareaWrapper {
      height: unset !important;
      max-height: unset !important;

      .ant-input {
        background-color: $light-gray-ten-percent;
      }

      .Form-Error {
        padding-top: unset;
        font-size: $fontSizeExtraExtraSmall;
      }

      .ant-input-disabled {
        background-color: $white;
        border-color: $white;
        color: $gray;
        font-size: $fontSizeSmall;
      }
    }
  }
}

.Button--Green {
  display: flex;
  align-items: center;
  border-radius: 1rem !important;
  background-color: $green !important;
  color: $white !important;
  border: none !important;

  svg {
    fill: $white !important;
  }
}

.Button--Orange {
  display: flex;
  align-items: center;
  border-radius: 1rem !important;
  background-color: $orange !important;
  color: $white !important;
  border: none !important;

  svg {
    fill: $white !important;
  }
}

.Button--Teal {
  display: flex;
  align-items: center;
  border-radius: 1rem !important;
  background-color: $teal !important;
  color: $white !important;
  border: none !important;

  svg {
    fill: $white !important;
  }
}

.Button--Red {
  display: flex;
  align-items: center;
  border-radius: 1rem !important;
  background-color: $red !important;
  color: $white !important;
  border: none !important;
}

.disabled {
  border: 1px solid $label-gray !important;
  color: $gray !important;
  background-color: $background !important;
  display: flex;
  align-items: center;

  svg {
    fill: $gray !important;
    width: 1.8rem;
    margin-right: 0.5rem;
  }
}
