.ant-picker {
  input {
    font-size: $fontSizeSmall;
  }
}

.ant-input,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-picker {
  width: 100%;
  border-radius: $borderRadiusXs;
  border-color: $neutral-gray;
  height: 4.2rem;
  font-size: $fontSizeSmall;

  &::placeholder {
    color: $label-gray;
  }

  @media only screen and (max-width: $breakpoint-md) {
    width: 100%;
  }

  &:hover {
    cursor: text;
  }

  &.Error {
    border-color: $red;
  }

  > .ant-input {
    height: auto;
  }
}

.ant-input-affix-wrapper {
  width: auto;
}

.ant-picker-input > input::placeholder {
  color: $label-gray;
}

.ant-input-textarea-show-count::after {
  color: $label-gray;
  padding: 1rem 0.5rem 0 0;
  font-size: $fontSizeExtraSmall !important;
  position: absolute;
  margin-top: -3.6rem;
  right: 1rem;
  display: flex;
  justify-content: flex-end;
}

.ant-input:disabled {
  cursor: not-allowed;
}

.ant-dropdown,
.ant-dropdown-menu,
.ant-dropdown-menu-root,
.ant-table-filter-dropdown {
  border-radius: $borderRadiusXs;
  padding: 0;
}

.ant-table-filter-dropdown-btns {
  border-radius: 0 0 $borderRadiusXs $borderRadiusXs;
}

.Input {
  &--Long {
    width: 52.8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &--Medium {
    width: 40rem;
  }

  &--Short {
    width: 12.6rem;

    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  // TODO - refactor High buttons names
  &--High {
    width: 31.6rem;
    height: 4.2rem;
    font-size: $fontSizeMediumSmall;

    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  &--Search {
    position: relative;
    width: 100%;
    margin-bottom: 1.6rem;
    max-width: 60rem;

    .ant-input {
      font-size: $fontSizeMedium;
      color: $dark-gray;

      &-prefix {
        width: 1.7rem;
        margin-right: 1.2rem;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  &--HighAndLong {
    width: 40rem;
    height: 4.2rem;
    font-size: $fontSizeMediumSmall;

    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
}

.react-tel-input {
  .form-control {
    width: 19.5rem;
    border-radius: $borderRadiusXs;
    border-color: $neutral-gray;
    height: 4.2rem;
    font-size: $fontSizeSmall;
    margin-left: 6.5rem;
    padding-left: 0.8rem;

    @media only screen and (max-width: $breakpoint-md) {
      width: calc(100% - 6.8rem);
    }

    &.Error {
      border-color: $red;
    }

    &:focus {
      border-color: $main-blue;
    }
  }

  .country-list {
    border-radius: $borderRadiusSm;
    box-shadow: 0 0.8rem 4rem rgba($black, 0.08);
    border: 0.1rem solid $neutral-gray;
    width: 26rem;

    > .country {
      color: $gray;

      &.highlight {
        color: $dark-gray;
        font-weight: $fontWeightBold;
      }
    }
  }

  .flag-dropdown {
    background-color: $white;
    border-radius: $borderRadiusXs;
    width: 6rem;

    &.Error {
      border-color: $red;
    }

    .selected-flag {
      border-radius: 1.2rem;
      width: 5.5rem;

      .arrow {
        border: solid $gray;
        border-width: 0 0.23rem 0.23rem 0;
        display: inline-block;
        padding: 0.35rem;
        transform: rotate(45deg);
        transition: transform 0.3s;
        top: -0.9rem;
        left: 3rem;
        opacity: 0.45;
        border-radius: 1px;

        /* &.up {
          transform: rotate(225deg) translate(-0.4rem, -0.2rem);
          transform-origin: center;
          border: solid $gray;
          border-width: 0 0.2rem 0.2rem 0;
        } */
      }
    }

    &:hover {
      .arrow {
        opacity: 0.9;
      }
    }

    &.open {
      border-radius: $borderRadiusXs;
      border-color: $main-blue;

      .arrow {
        opacity: 0.9;
      }

      .selected-flag {
        border-radius: 1.2rem;
      }
    }
  }
}

textarea {
  width: 100%;
  padding: 1.2rem;
  resize: none;
  border-radius: $borderRadiusXs;
  border-color: $neutral-gray;
  font-size: $fontSizeSmall;
  transition: all 0.3s;

  &:hover {
    border-color: $main-blue;
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: $main-blue;
    box-shadow: 0 0 0.4rem 0.2rem $focus-blue;
    outline: none;
  }
}
