@import 'styles/helpers';
@import 'styles/typography';

.Sidebar {
  &-Footer-Wrapper {
    margin-bottom: 0.5rem;
  }

  &-Confirm {
    .ant-modal-confirm-btns {
      width: 100%;
      display: flex;
      align-items: flex-end;
      margin-top: 0;
    }

    &-Wrapper {
      margin-top: 1rem;
      display: flex;
    }

    &-Name {
      margin-right: 1rem;
      overflow-wrap: break-word;
      flex: 2;
    }

    &-Email {
      overflow-wrap: anywhere;
      flex: 3;
    }
  }

  &-CreditsWrapper {
    display: flex;
  }

  &-Title {
    margin-right: 1rem;
  }

  &-RefreshIcon {
    height: 1.5rem;
    margin-right: 1rem;

    &--Spinning {
      color: $main-blue;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:hover {
      color: $main-blue;
      cursor: pointer;
    }
  }

  &-RefreshWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3rem;
    font-size: $fontSizeExtraExtraSmall;
    font-weight: $fontWeightSemiBold;
    color: $gray;
    margin-left: 4.5rem;
  }

  &-InfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;

    p {
      margin-bottom: 0;
    }
  }

  &-NoDisplay {
    display: none;
  }
}
