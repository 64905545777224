.comments a {
  background: rgb(28, 72, 242);
  color: white;
  border-radius: 5px;
  padding: 0.2em 0.2em 0.2em;
}

.comments a:hover {
  pointer-events: none !important;
  cursor: text !important;
}
