@import 'styles/helpers';

.AppError {
  text-align: center;

  @media only screen and (max-width: $breakpoint-lg) {
    text-align: left;
  }

  .ant-card {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $neutral-gray;

    > .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8rem;
      min-width: 42rem;
    }

    svg {
      width: 5.5rem;
      height: 5.5rem;
      margin-bottom: 2.4rem;
    }

    p {
      margin-top: 1rem;
    }

    a {
      margin-left: 0.5rem;
    }
  }
}
