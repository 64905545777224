@import 'styles/helpers';
@import 'styles/typography';

.DesignSteps {
  &-MainWrapper {
    width: 90%;
    min-width: 80rem;
    padding: 3rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-steps {
      width: unset;
    }

    .ant-steps-item-content {
      padding-top: 1.5rem !important;
      margin: 0;
    }

    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-steps-icon {
      top: -2.5px;
    }

    .ant-steps-item-icon {
      margin: 0;
      background: inherit !important;
      border-width: 2px !important;
    }

    .ant-steps-item-tail::after {
      margin: 0;
      left: 5.8rem;
      border-radius: 4px !important;
    }

    .ant-steps-item-wait {
      .ant-steps-item-tail::after {
        background-color: $neutral-gray;
        height: 0.5rem;
      }

      :hover * {
        cursor: default;
        color: $gray !important;
        border-color: $gray !important;
      }
    }

    .ant-steps-item-active {
      .ant-steps-icon {
        color: $main-blue !important;
      }

      .ant-steps-item-icon {
        border-color: $main-blue !important;
      }

      .ant-steps-item-tail::after {
        background-color: $neutral-gray;
        height: 0.5rem;
        border-radius: 5px !important;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: $main-blue !important;
      }

      .ant-steps-icon {
        color: white !important;
      }

      .ant-steps-item-tail::after {
        background-color: $main-blue;
        height: 0.5rem;
      }

      .ant-steps-item-container {
        .ant-steps-item-description {
          color: $main-blue !important;
        }
      }
    }

    .ant-steps-item-active .ant-steps-item-description {
      font-weight: $fontWeightSemiBold;
    }

    .steps-content {
      min-width: 100% !important;
      display: flex;
      justify-content: center;
    }
  }
}
