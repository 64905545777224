@import 'styles/helpers/';
@import 'styles/typography/';

.DesignServices {
  &-Wrapper {
    padding: 0 3.2rem;
  }

  &-Spinner {
    margin-bottom: 2.9rem;
  }

  &-Search {
    flex-grow: 1;
    margin: 0 0.5rem 0 2rem;
    height: 3.8rem !important;

    &-prefix {
      width: 1.7rem;
      margin-right: 1.2rem;
    }

    .ant-input {
      font-size: $fontSizeMedium;
      margin-left: 0.5rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 50% !important;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 30% !important;
    }
  }

  &-TotalCreditDisplayWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-CreditInput {
    max-width: 13rem;
  }

  &-SubHeader {
    display: flex;
    justify-content: space-between;
    margin: 3.4rem 0 2rem 0;

    p {
      margin: 0.65rem 3.5rem 0 2rem;
      color: $label-gray;
      font-size: $fontSizeMedium;
    }

    .Button--Blue {
      svg {
        margin-right: 8px;

        path {
          fill: $white;
        }
      }

      margin: 0 1rem;

      > button {
        height: 3.8rem !important;
        box-shadow: none;
        text-shadow: none;
        background-color: $light-gray !important;
        border: 0.1rem solid $neutral-gray !important;
        color: $white !important;
      }
    }

    & .Button {
      &--White {
        margin: 0 1rem;
      }
    }

    @media only screen and (max-width: $breakpoint-xl) {
      flex-wrap: wrap;
      justify-content: flex-end;
      row-gap: 2rem;
    }

    @media only screen and (max-width: $breakpoint-md) {
      p {
        display: none;
      }
    }
  }

  &-Button {
    margin-top: 0;
  }

  &-ButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }

  &-OrderButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 0;

    &-CopyIcon {
      margin-left: 8px;
      font-size: 1.5rem;
      cursor: pointer;

      svg path {
        fill: $dark-gray;
      }

      &:hover {
        svg path {
          fill: $main-blue;
        }
      }
    }
  }

  &-Table {
    overflow: auto;
    background: $white;
    margin: 0.5rem 0;
    border-radius: $borderRadiusXs;
    margin-bottom: 7rem;
    height: calc(100vh - 29rem);

    .ant-pagination {
      margin-right: 2.55rem !important;
      margin-left: 2.55rem !important;
      position: fixed;
      bottom: 0.5rem;
      z-index: 0;

      &-options {
        display: none;
      }
    }

    .ant-table-cell {
      white-space: pre-wrap;

      .Button--Icon {
        height: unset !important;
        padding: 0.75rem !important;

        svg {
          height: 1.25rem;
        }
      }
    }

    th.ant-table-row-expand-icon-cell,
    td.ant-table-row-expand-icon-cell {
      width: 6rem;
    }

    th.ant-table-cell-scrollbar,
    td.ant-table-cell-scrollbar {
      display: none;
    }

    tr.ant-table-expanded-row-level-1 {
      padding: 2rem !important;
    }

    .ant-table-expanded-row-level-1 > .ant-table-cell {
      padding: 0;
    }

    .ant-table,
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background: $white;
      z-index: 1;
    }

    td.ant-table-cell-fix-right,
    td.ant-table-cell-fix-left {
      font-weight: $fontWeightSemiBold;
    }

    .ant-table-row {
      background: $white;
    }

    .Button {
      &--White {
        svg {
          margin-right: 5px;

          path {
            fill: $dark-gray;
          }
        }

        &:hover,
        &:focus,
        &:active {
          svg path {
            fill: $main-blue;
          }
        }

        &:disabled {
          svg path {
            fill: $mid-gray;
          }
        }
      }

      &--Blue {
        svg {
          margin-right: 8px;

          path {
            fill: $white;
          }
        }

        margin: 0 1rem;
      }
    }

    label {
      margin-bottom: -0.15rem;
    }
  }
}
