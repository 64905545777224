@import 'styles/helpers/';
@import 'styles/typography/';

.ActionButtons {
  &-Wrapper {
    display: flex;

    .ant-dropdown-menu-item {
      padding: unset;
    }
  }

  &-Button {
    position: relative;
    background-color: $white;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $neutral-gray;
    border-radius: 0.75rem;
    margin-right: 0.5rem;

    :hover {
      cursor: pointer;
    }

    svg {
      max-width: 1.65rem;
      max-height: 1.35rem;
    }
  }

  &-DropdownMenuButton {
    padding: unset;
    border: unset;
    background: unset;
    font-size: $fontSizeExtraSmall;
  }

  &-DropdownMenuIcon {
    margin-right: 1rem;
    width: 1rem;
    fill: inherit;
  }
}

.ant-dropdown-menu-item {
  :hover {
    cursor: pointer;
  }
}

.ant-tooltip-inner {
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  font-size: $fontSizeExtraSmall;
}
