label {
  margin-bottom: 0.4rem;
  font-size: $fontSizeSmall;
  color: $label-gray;
  display: block;
}

.Label--Big {
  font-weight: $fontWeightNormal;
  margin-bottom: 1rem;
  font-size: $fontSizeMediumSmall;
}
