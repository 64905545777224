@import 'styles/helpers/';
@import 'styles/typography';

.DesignService {
  font-size: $fontSizeSmall;
  color: $label-gray;
  margin: 0 auto;
  width: 100%;

  .additional-info {
    font-size: $fontSizeExtraExtraSmall;

    .additional-info-title {
      font-size: $fontSizeSmall;
    }

    textarea {
      margin-bottom: 2rem;
    }
  }

  .ant-radio-wrapper {
    font-size: $fontSizeSmall;
    color: $label-gray;
  }

  .ant-col {
    .uppy {
      &-DragDrop {
        &-container {
          border-radius: 10px;
          border: 1px dashed $label-gray;
          background-color: #f5f5f5;
          cursor: pointer;
          width: 100% !important;
        }

        &-inner {
          height: 8rem !important;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 0.156rem;
        }

        &-label {
          color: $dark-gray;
          font-size: $fontSizeMedium;
          vertical-align: middle;
          font-family: 'Poppins', Helvetica, Arial, sans-serif;

          &::before {
            display: block;
            content: url('../../../../../../assets/icons/arrow-circle.svg');
            width: 2rem;
            height: 2rem;
            margin-top: 0.2rem;
            float: left;
            margin-right: 0.8rem;
          }
        }

        &--isDraggingOver {
          border-color: $main-blue;
          transition: 300ms ease-in-out;

          .uppy-DragDrop-label {
            color: $main-blue;

            &::before {
              content: url('../../../../../../assets/icons/arrow-circle-blue.svg') !important;
            }
          }
        }

        &-browse {
          color: $main-blue;
        }

        &-arrow {
          display: none;
        }
      }

      &-Root {
        margin: 0.65rem 0;
        cursor: pointer;
        width: 100% !important;
        text-align: center;

        &:hover,
        &:active {
          border-color: $main-blue;

          .uppy-DragDrop-label {
            color: $main-blue;

            &::before {
              content: url('../../../../../../assets/icons/arrow-circle-blue.svg');
            }
          }
        }
      }
    }
  }

  .files-uploaded {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 12rem;
    overflow: auto;
    margin-top: 1.25rem;
    box-sizing: border-box;

    .file-uploaded {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      width: 49%;

      .indicator-icon-wrapper {
        width: 2.5rem;
        padding-top: 0.3rem;
        margin-right: 0.5rem;
      }

      &-content {
        display: flex;
        flex-direction: row;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 0.4rem 0.6rem;
        margin-right: 0.6rem;
        min-width: 90%;
        height: 3.5rem;
        font-size: $fontSizeExtraExtraSmall;
        color: $label-gray;
        box-sizing: border-box;

        .document-icon {
          width: 1.5rem;
          padding-top: 0.3rem;
        }

        .file-data {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.2rem;
          min-width: 95%;

          &-labels {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;

            .file-name {
              padding-left: 0.5rem;
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .file-uploaded-progress-wrapper {
            margin-left: 0.5rem;
            margin-top: 0.063rem;

            .file-uploaded-progress {
              height: 0.063rem;
              border-radius: 0.3rem;
              border: 2px solid $main-blue;
            }
          }
        }
      }
    }
  }
}
