@import 'styles/helpers';
@import 'styles/typography';

.ScaniflyAdminMenu {
  display: flex;
  justify-content: center;
  align-self: center;

  &-Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: calc(100vh - 9.4rem);
    padding: 1rem 0;
    align-content: center;
    width: calc((45.6rem + 1.6rem) * 2);

    @media only screen and (max-width: $breakpoint-xl) {
      padding: 1rem 0;
      width: calc((45.6rem + 1rem));
    }

    @media only screen and (max-width: $breakpoint-sm) {
      min-height: calc(100vh - 8rem);
      width: 90%;
    }
  }

  &-Row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xl) {
      justify-content: center;
    }
  }

  &-Button {
    width: 45.6rem;
    height: 12.6rem;
    background-color: $white;
    padding: 3.2rem 2.8rem;
    border: 0.1rem solid $neutral-gray;
    border-radius: $borderRadiusSm;
    margin: 0 1.6rem 1.6rem 0;
    font-weight: $fontWeightBold;
    font-size: $fontSizeLarge;
    color: $dark-gray;
    display: flex;
    align-items: center;
    position: relative;

    @media only screen and (max-width: $breakpoint-lg) {
      margin: 0 0 1.6rem 0;
    }

    @media only screen and (max-width: $breakpoint-xxs) {
      height: fit-content;
      padding: 1rem;
    }

    &-Text {
      position: absolute;
      left: 14rem;

      @media only screen and (max-width: $breakpoint-md) {
        left: 12rem;
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        position: relative;
        left: 0;
      }
    }

    &-Icon {
      width: 7.5rem;
      height: 7.5rem;
      margin-right: 1.25rem;

      .dollar-sign {
        fill: $main-blue !important;
      }

      &--Admin-Accounting {
        width: 10rem;
        height: 10rem;
        margin-right: 1.25rem;
      }

      @media only screen and (max-width: $breakpoint-xxs) {
        display: none;
      }
    }
  }
}
