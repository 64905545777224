.ant-checkbox {
  overflow: hidden;
  top: 0;

  &-inner {
    width: 2.2rem;
    height: 2.2rem;
    border: 0.1rem solid $neutral-gray;
    background-color: $white;
    border-radius: $borderRadiusXXs;

    &:focus {
      border-color: $main-blue;
    }

    &:hover {
      border-color: $main-blue;
    }
  }

  &-checked {
    overflow: hidden;
    border-radius: $borderRadiusXXs;
    border-color: $main-blue;

    & .ant-checkbox-inner {
      background-color: $main-blue !important;
      border: 0.1rem solid $main-blue;

      &::after {
        left: 28%;
      }
    }
  }

  &:hover .ant-checkbox-inner {
    border-color: $main-blue;
  }
}
