@import 'styles/helpers';
@import 'styles/typography';

.OrderResponseModal {
  &-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 45rem;
  }

  &-Title {
    font-weight: $fontWeightSemiBold;
    max-width: 75%;
    font-size: $fontSizeLarge;
    margin: 1.5rem 0 1rem 0;
    text-align: center;
  }

  &-Subtitle {
    color: $gray;
    font-weight: $fontWeightNormal;
    font-size: $fontSizeExtraExtraSmall;
    margin-bottom: 2rem;
  }

  &-Text {
    max-width: 75%;
    text-align: center;
    margin: 1rem 0;
  }

  &-ErrorIcon {
    height: 10rem;
    width: auto;
  }

  &-DroneIcon {
    height: 5rem;
    width: auto;
  }

  &-Info {
    min-width: 100%;

    > hr {
      border: 0 none !important;
      border-top: 2px dotted $light-gray !important;
      background: none;
    }
  }

  &-InfoTitles {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 1.5rem;
    margin-top: 1rem;

    h2 {
      font-size: $fontSizeSmall;
      margin-bottom: 0;
      font-weight: $fontWeightNormal !important;
    }

    .heading {
      color: $black !important;
      font-weight: $fontWeightSemiBold !important;
    }

    p {
      margin: 0 !important;
      font-size: $fontSizeMediumSmall;
      color: $gray;
    }
  }

  &-TotalWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2rem;
  }

  &-TotalValue {
    font-weight: $fontWeightSemiBold;
    margin: 0;
  }

  &-Value {
    font-weight: unset;
  }

  &-Button {
    margin-top: 2rem;
  }
}
