@import 'styles/helpers';
@import 'styles/typography';

.Note {
  position: relative;

  &.ant-card {
    border-radius: $borderRadiusSm;
    border: 0.1rem solid $neutral-gray;
    padding: 1rem 1.4rem;
    margin: 1rem 0;

    &:not(:first-child) {
      margin: 2.8rem 0 1.4rem 0;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  &--Selected {
    &.ant-card {
      border: 0.1rem solid $main-blue;
    }
  }

  &-Avatar {
    display: block;
    height: 4.2rem;
    width: 4.2rem;
    background: $blue-gray;
  }

  &-Footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }

  &-Menu {
    position: absolute;
    right: 1.2rem;
    top: 0.8rem;
  }

  &-Name {
    font-weight: $fontWeightBold;
    font-size: $fontSizeMediumSmall;
    line-height: 1.8rem;
    overflow-wrap: break-word;
    color: $dark-gray;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.2rem;
  }

  &-Role {
    color: $label-gray;
    margin: 0.5rem 0;
    font-size: $fontSizeSmall;
    line-height: 1.6rem;
    text-transform: capitalize;
    overflow-wrap: break-word;
    max-width: 17rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-Thumbnail {
    width: 6.7rem;
    height: 6.7rem;
    margin-right: 1.4rem;
    margin-left: -0.4rem;
    border-radius: $borderRadiusXXs;
    object-fit: cover;
    background-color: $blue-gray;

    &-Count {
      position: absolute;
      left: 6.25rem;
      top: 0.55rem;
      cursor: pointer;

      .ant-badge-count {
        background: $light-blue;
        box-shadow: 0 0 0 0.12rem $background;
      }
    }

    &--Video {
      margin-bottom: -0.7rem;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(80%);
      transition: all 0.3s ease;
    }
  }

  &-User {
    display: flex;
    flex-direction: row;
    gap: 1.4rem;
  }

  &-Updated {
    color: $label-gray;
    margin-top: 0.4rem;
    font-size: $fontSizeExtraSmall;
    text-align: right;

    & p {
      margin-bottom: 0;
    }
  }

  &-Wrapper {
    display: flex;
    flex-direction: row;
    word-break: break-word;

    &--Menu {
      padding-right: 2.1rem;
    }
  }
}
