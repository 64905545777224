@import 'styles/helpers/';
@import 'styles/typography';

.ItemsUploadedRadioButtons {
  margin-bottom: 1rem;

  .radio-button-wrapper {
    display: flex;
    flex-direction: column;

    .ant-radio-wrapper {
      font-size: $fontSizeSmall;
      color: $label-gray;
    }
  }
}
