@import 'styles/helpers';
@import 'styles/typography';

.Header {
  padding: 0 3.2rem;
  height: 9.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $header-shadow;
  background: $white;
  z-index: 4;

  &-Button {
    margin: 0 0.4rem 0 0;
    background: $white;
    border: none;
    padding: 0;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-MenuButton {
    border: 0.1rem solid $light-gray;
    border-radius: $borderRadiusXl;
    padding: 0.4rem;
    height: 4.8rem;
    text-align: start;
    font-size: $fontSizeMediumSmall;

    > .ant-avatar {
      margin-top: -0.1rem;
    }

    &-Name {
      margin-left: 0.8rem;
      margin-right: 1.2rem;
    }
  }

  &-MenuWrapper {
    position: absolute;
    top: 7.8rem;
    right: 3.2rem;
    min-width: 16rem;
    background: $white;
    border: 0.1rem solid $neutral-gray;
    border-radius: $borderRadiusSm;
    padding: 1.8rem;
    padding-bottom: 0;
    z-index: 3;
  }

  &-Menu {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: $fontSizeExtraSmall;

    &Item {
      width: 100%;
      display: flex;
      align-items: center;
      color: $dark-gray;
      cursor: pointer;

      &:hover {
        color: $main-blue;
      }

      svg {
        height: 1.65rem;
        margin-right: 1rem;
      }
    }

    li {
      margin-bottom: 1.25rem;
    }
  }

  @media only screen and (max-width: $breakpoint-sm) {
    height: 8rem;

    &-MenuButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.8rem;
      padding: 0;

      > .ant-avatar {
        width: 100% !important;
        height: 100% !important;
      }

      span:not(.ant-avatar) {
        display: none;
      }
    }

    &-MenuWrapper {
      top: 6.5rem;
    }
  }

  @media only screen and (max-width: $breakpoint-xs) {
    padding: 0 2rem;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    .MainTitle {
      line-height: 2rem;
      font-size: $fontSizeLarge;
    }
  }
}
